import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError, MatChipInputEvent } from '@angular/material';
declare var jQuery: any;
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { env } from 'process';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class InfoComponent implements OnInit {
  ref: any = [];
  items: any[];
  selectedRowIndex = [];
  displayedColumns = ['id', 'category'];
  loadRef: any = [];
  dialogRef: any = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  listmenu: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;
  obs: Observable<any>;
  mainDb = environment.mainDb;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadData();
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  loadData() {
    this.listmenu=[];
    this.db.list(this.mainDb + '/timeline/').snapshotChanges().subscribe(datas => {
      datas.forEach(data => {
        this.listmenu.push(GetListMenu(data.payload));
      });
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listmenu);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.obs = this.dataSource.connect();
      }, 500);
    });
  }
  delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.judul + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref(this.mainDb + '/timeline/' + item.id).remove();
      this.storage.ref(item.filename).delete();
      this.closeSpinner();
    }
  }
  AddData() {
    const dialog = this.dialog.open(addInfo, {
      hasBackdrop: true,
      panelClass: 'dialog',
      width: '30%',
      data: {
        action: 'new',
      }
    });
  }

  getRecord(item) {
    const dialog = this.dialog.open(addInfo, {
      hasBackdrop: true,
      panelClass: 'dialog',
      width: '30%',
      data: {
        action: 'edit',
        DataInfo: item,
      }
    });
  }
}

@Component({
  selector: 'addmahasiswa',
  templateUrl: 'tambahinfo/tambahinfo.component.html',
  styleUrls: ['tambahinfo/tambahinfo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class addInfo {
  modelInfo: any = [];
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  nama: any;
  alamat: any;
  jumlah: any;
  oldGambar: any;
  flag: boolean;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  currentUnixTime = Date.now();
  title: string = '';
  mainDb = environment.mainDb;
  constructor(
    public dialogRef: MatDialogRef<addInfo>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  ngOnInit(): void {
    console.log(this.data);
    if (this.data.action === 'new') {
      this.title = 'Info Baru';
      this.flag = false;
    } else if (this.data.action === 'edit') {
      this.title = 'Edit Info';
      this.modelInfo = this.data.DataInfo;
      this.flag = true;
      this.gambar = this.data.DataInfo.image;
      this.key = this.data.DataInfo.id;
      console.log(this.key);
      this.oldGambar = this.data.DataInfo.image;
    }
  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }

  getFileAttachment(event: any) {
    this.imageChangedEvent = event;
    //   if (event.target.files && event.target.files[0]) {
    //     const file = event.target.files[0];

    //     const reader = new FileReader();
    //     reader.onload = e => this.gambar = reader.result;
    //     reader.readAsDataURL(file);
    // }
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.file;
    this.gambar = event.base64;

  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  simpan() {
    if (this.modelInfo.judul === '') {
      alert('Judul harus di isi !!!');
      return;
    }

    this.loadSpinner();
    const file = this.croppedImage;
    const filePath = 'timeline/' + this.modelInfo.judul + '.webp';

    // this.db.database.ref('timeline/' + this.key + '/').set({
    //   'Judul': this.modelInfo.judul !== undefined ? this.modelInfo.judul.toString() : "",
    //   'subJudul': this.modelInfo.subJudul !== undefined ? this.modelInfo.subJudul.toString() : "",
    //   'deskripsi': this.modelInfo.deskripsi !== undefined ? this.modelInfo.deskripsi.toString() : "",
    //   'image': this.modelInfo.image,
    //   'status': false,
    //   'link': this.modelInfo.link !== undefined ? this.modelInfo.link.toString() : "",
    // })

    if (this.data.action === 'edit') {
      if (this.croppedImage === '') {
        this.db.database.ref(this.mainDb + '/timeline/' + this.key + '/').set({
          'Judul': this.modelInfo.judul !== undefined ? this.modelInfo.judul.toString() : "",
          'subJudul': this.modelInfo.subJudul !== undefined ? this.modelInfo.subJudul.toString() : "",
          'deskripsi': this.modelInfo.deskripsi !== undefined ? this.modelInfo.deskripsi.toString() : "",
          'image': this.modelInfo.image,
          'namaGambar': this.modelInfo.judul + '.webp',
          'status': false,
          'link': this.modelInfo.link !== undefined ? this.modelInfo.link.toString() : "",
        }).then(() => {
          this.closeSpinner();
          this.dialogRef.close();
        });
      } else {
        const fileRef = this.storage.ref(filePath);
        const task = fileRef.put(this.croppedImage);
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(url => {
          if (url) {
            this.db.database.ref(this.mainDb + '/timeline/' + this.key + '/').set({
              'Judul': this.modelInfo.judul !== undefined ? this.modelInfo.judul.toString() : "",
              'subJudul': this.modelInfo.subJudul !== undefined ? this.modelInfo.subJudul.toString() : "",
              'deskripsi': this.modelInfo.deskripsi !== undefined ? this.modelInfo.deskripsi.toString() : "",
              'image': url,
              'namaGambar': this.modelInfo.judul + '.webp',
              'status': false,
              'link': this.modelInfo.link !== undefined ? this.modelInfo.link.toString() : "",
            }).then(() => {
              this.closeSpinner();
              //if (this.oldGambar !== filePath) {
              this.storage.ref(this.oldGambar).delete();
              // }
              this.dialogRef.close();
            })
          }
        });
      }
    } else {
      const fileRefr = this.storage.ref(filePath);
      const task = fileRefr.put(this.croppedImage);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRefr.getDownloadURL()
          this.downloadURL.subscribe(url => {
            if (url) {
              this.db.database.ref(this.mainDb + '/timeline').push(
                {
                  'Judul': this.modelInfo.judul !== undefined ? this.modelInfo.judul.toString() : "",
                  'subJudul': this.modelInfo.subJudul !== undefined ? this.modelInfo.subJudul.toString() : "",
                  'deskripsi': this.modelInfo.deskripsi !== undefined ? this.modelInfo.deskripsi.toString() : "",
                  'image': url,
                  'namaGambar': this.modelInfo.judul + '.webp',
                  'status': false,
                  'link': this.modelInfo.link !== undefined ? this.modelInfo.link.toString() : "",
                }
              ).then(() => {
                this.closeSpinner();
                this.dialogRef.close();
              })
            }
          })
        })
      ).subscribe();
    }
  }
}

function GetListMenu(data): MenuData {
  return {
    id: data.key,
    deskripsi: data.val()['deskripsi'],
    image: data.val()['image'],
    namaGambar: data.val()['namaGambar'],
    judul: data.val()['Judul'],
    link: data.val()['link'],
    subJudul: data.val()['subJudul'],
  };
}

export interface MenuData {
  id: string;
  deskripsi: string;
  image: string;
  judul: string;
  link: string;
  namaGambar: String;
  subJudul: string;
}
export const COMPONENT_LIST = [
  InfoComponent,
  addInfo
]
