import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable, of } from 'rxjs';
import { finalize, subscribeOn, startWith, tap, delay } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import * as moment from 'moment';
import { environment } from 'environments/environment';

declare var jQuery: any;

@Component({
  selector: 'app-ppdb',
  templateUrl: './ppdb.component.html',
  styleUrls: ['./ppdb.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class PpdbComponent implements OnInit {
  displayedColumns = ['nisn', 'nama', 'alumni', 'tgllahir'];
  dataSource: MatTableDataSource<ippdb>;
  listppdb: ippdb[] = [];
  listJurusan: iJurusan[] = [];
  isLoading = true;
  mainDb = environment.mainDb;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadPpdb();
    let tgl = new Date();

    this.db.list(this.mainDb + '/jurusan/').snapshotChanges().subscribe(items => {
      this.listJurusan = [];
      items.forEach(item => {
        this.listJurusan.push(getJurusan(item.key, item.payload.val()['nama']));
        // console.log(this.listJurusan);
      });
    });
  }

  loadPpdb() {
    this.db.list(this.mainDb + '/ppdb/').snapshotChanges().subscribe(tahuns => {
      this.listppdb = [];
      tahuns.forEach(tahun => {
        let tahunKey = tahun.key;
        this.db.list(this.mainDb + '/ppdb/' + tahunKey + '/').snapshotChanges().subscribe(users => {
          users.forEach(user => {
            this.listppdb.push(getIppdb(tahunKey, user.key, user.payload.val()['casis'][0],
              user.payload.val()['casisOrtu'][0], user.payload.val()['casisWali'][0], user.payload.val()['status']));
            console.log(this.listppdb);
          });
          setTimeout(() => {
            of(this.listppdb).pipe(delay(800))
              .subscribe(data => {
                console.log(data[0]);
                if (data[0].status === "0") {
                  this.isLoading = false;
                  this.dataSource = new MatTableDataSource(data);
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort;
                }
              }, error => this.isLoading = false);
          }, 500);
        });
      });
    });
  }

  getRow(item) {
    console.log(item);
    const dialog = this.dialog.open(detailppdb, {
      hasBackdrop: true,
      panelClass: 'dialog',
      width: '50%',
      data: {
        action: 'detail',
        ppdb: item,
        listjurusan: this.listJurusan,
      }
    });
  }
}
@Component({
  selector: 'detailppdb',
  templateUrl: 'detailppdb/detailppdb.html',
  styleUrls: ['detailppdb/detailppdb.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class detailppdb {
  ModelPpdb: any = [];
  listJurusan: iJurusan[] = [];
  ppdbForm: FormGroup;
  mainDb = environment.mainDb;

  constructor(
    public dialogRef: MatDialogRef<detailppdb>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;


  ngOnInit(): void {
    // console.log(this.data);
    this.listJurusan = this.data.listjurusan;
    this.ModelPpdb.asal = this.data.ppdb.casis.ppdbAsalSmp;
    this.ModelPpdb.nisn = this.data.ppdb.casis.ppdbNisn;
    this.ModelPpdb.nama = this.data.ppdb.casis.ppdbNamaLengkap;
    this.ModelPpdb.gender = this.data.ppdb.casis.ppdbJenisKelamin;
    this.ModelPpdb.agama = this.data.ppdb.casis.ppdbAgama;
    this.ModelPpdb.jurusan = this.data.ppdb.casis.ppdbJurusan;
    this.ModelPpdb.alamat = this.data.ppdb.casis.ppdbAlamat;
    this.ModelPpdb.tempatlahir = this.data.ppdb.casis.ppdbTempatLahir;
    this.ModelPpdb.tgllahir = new Date(Number(this.data.ppdb.casis.ppdbTglLahir));

    this.ModelPpdb.ayah = this.data.ppdb.casisOrtu.ppdbOrtuNamaAyah;
    this.ModelPpdb.agamaAyah = this.data.ppdb.casisOrtu.ppdbOrtuAgamaAyah;
    this.ModelPpdb.pendidikanAyah = this.data.ppdb.casisOrtu.ppdbOrtuPendAyah;
    this.ModelPpdb.pengeluaranAyah = this.data.ppdb.casisOrtu.ppdbOrtuPengeluaranAyah;
    this.ModelPpdb.ibu = this.data.ppdb.casisOrtu.ppdbOrtuNamaIbu;
    this.ModelPpdb.agamaIbu = this.data.ppdb.casisOrtu.ppdbOrtuAgamaIbu;
    this.ModelPpdb.pendidikanIbu = this.data.ppdb.casisOrtu.ppdbOrtuPendIbu;
    this.ModelPpdb.pengeluaranIbu = this.data.ppdb.casisOrtu.ppdbOrtuPengeluaranIbu;

    this.ModelPpdb.wali = this.data.ppdb.casisWali.ppdbWaliNamaWali;
    this.ModelPpdb.agamaWali = this.data.ppdb.casisWali.ppdbWaliAgamaWali;
    this.ModelPpdb.pendidikanWali = this.data.ppdb.casisWali.ppdbWaliPendWali;
    this.ModelPpdb.pengeluaranWali = this.data.ppdb.casisWali.ppdbWaliPengeluaranWali;

    this.ppdbForm = new FormGroup({
      asal: new FormControl(''),
      nisn: new FormControl(''),
      nama: new FormControl(''),
      gender: new FormControl(''),
      agama: new FormControl(''),
      jurusan: new FormControl(''),
      alamat: new FormControl(''),
      tempatlahir: new FormControl(''),
      tgllahir: new FormControl(''),

      ayah: new FormControl(''),
      agamaAyah: new FormControl(''),
      pendidikanAyah: new FormControl(''),
      pengeluaranAyah: new FormControl(''),
      ibu: new FormControl(''),
      agamaIbu: new FormControl(''),
      pendidikanIbu: new FormControl(''),
      pengeluaranIbu: new FormControl(''),

      wali: new FormControl(''),
      agamaWali: new FormControl(''),
      pendidikanWali: new FormControl(''),
      pengeluaranWali: new FormControl(''),
    })
  }

  simpan() {
    this.db.database.ref(this.mainDb+'/siswa/' + this.data.ppdb.uid + '/').set({
      'nama': this.ModelPpdb.nama,
      'alamat': this.ModelPpdb.alamat,
      'gender': this.ModelPpdb.gender,
      'tempatlahir': this.ModelPpdb.tempatlahir,
      'tgllahir': this.ModelPpdb.tgllahir.valueOf(),
      'jurusan': this.ModelPpdb.jurusan,
    }).then(() => {
      this.dialogRef.close();
      this.db.database.ref(this.mainDb+'/ppdb/' + this.data.tahun + '/' + this.data.uid + '/').set({
        'status': '0',
      })
    });
  }
}

function getIppdb(tahun: string, uid: string, casis: any[], casisOrtu: any[], casisWali: any[], status: string): ippdb {
  return {
    tahun: tahun,
    uid: uid,
    casis: casis,
    casisOrtu: casisOrtu,
    casisWali: casisWali,
    status: status,
  }
}

function getJurusan(id: string, nama: string): iJurusan {
  return {
    id: id,
    nama: nama
  };
}

export interface ippdb {
  tahun: string,
  uid: string,
  casis: any[],
  casisOrtu: any[],
  casisWali: any[],
  status: string,
}
export interface iJurusan {
  id: string,
  nama: string
}
export const COMPONENT_LIST = [
  PpdbComponent, detailppdb,
];

