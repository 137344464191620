import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn, startWith, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { DialogJadwal, COMPONENT_SUBLIST } from './jadwal/jadwal.component';
import { environment } from 'environments/environment';
declare var jQuery: any;

@Component({
  selector: 'kelas',
  templateUrl: './kelas.component.html',
  styleUrls: ['./kelas.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class KelasComponent implements OnInit {
  dataSource: MatTableDataSource<MenuData>;
  obs: Observable<any>;
  dialogRef: any = [];
  listguru: iGuru[] = [];
  listJurusan: iJurusan[] = [];
  listSubJurusan: iSubJurusan[] = [];
  DataKelas: MenuData[] = [];
  listTingkat: iTingkat[] = [];
  mainDb = environment.mainDb;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  currentUnixTime = Date.now();
  arr1: any = [];
  arr2: any = [];
  arr3: any = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */


  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() {
    this.loadkelas();
    this.getJurusanKelas();
    this.tingkat();
  }

  // fire(){
  //   this.db.database.ref('jadwal/-M0m4A2pUazgjjNvuz0d/123121231/1231212311/2/' + this.currentUnixTime).set({
  //     'jam':'07:00 - 07:35',
  //     'matapelajaran' : 'Bahasa Inggris',
  //     'guru':'Guru 5',
  //   })
  // }

  tingkat() {
    this.db.list(this.mainDb + '/tingkatan')
      .snapshotChanges()
      .subscribe(items => {
        this.listTingkat = [];
        items.forEach(item => {
          this.listTingkat.push(getTingkatan(item.payload));
        })
      });
  }

  loadGuru() {
    this.db.list(this.mainDb + '/pengajar')
      .snapshotChanges()
      .subscribe(items => {
        this.listguru = [];
        items.forEach(item => {
          this.listguru.push(GetListMenu(item.payload));
          // console.log(this.listguru);
        });
      });
  }

  loadkelas() {
    this.loadSpinner();
    this.loadGuru();
    this.db.list(this.mainDb + '/kelas/').snapshotChanges().subscribe(result => {
      result.forEach(res => {
        let idkelas = res.key;
        this.db.list(this.mainDb + '/kelas/' + idkelas).snapshotChanges().subscribe(items => {
          this.DataKelas = [];
          items.forEach(item => {
            let idJurusan = item.key;
            this.db.list(this.mainDb + '/kelas/' + idkelas + '/' + idJurusan).snapshotChanges().subscribe(details => {
              details.forEach(detail => {
                this.DataKelas.push(getListData(idkelas, idJurusan, detail.key, detail.payload.val()['walikelas']));
              });
              setTimeout(async () => {
                this.arr1 = [];
                this.arr2 = [];
                this.arr3 = [];
                this.DataKelas.forEach(dtkls => {
                  this.listTingkat.forEach(dtTingkat => {
                    if (dtkls.idkelas === dtTingkat.id) {
                      this.arr1.push({
                        'idtingkat': dtkls.idkelas,
                        'tingkat': dtTingkat.tingkat,
                        'idjurusan': dtkls.jurusan,
                        'iddetailjurusan': dtkls.detailJurusan,
                        'walikelas': dtkls.walikelas,
                      });
                    }
                  });
                });

                this.arr1.forEach(tmp => {
                  this.listJurusan.forEach(jur => {
                    if (tmp.idjurusan === jur.id) {
                      this.arr2.push({
                        'idtingkat': tmp.idtingkat,
                        'tingkat': tmp.tingkat,
                        'idjurusan': tmp.idjurusan,
                        'jurusan': jur.nama,
                        'iddetailjurusan': tmp.iddetailjurusan,
                        'walikelas': tmp.walikelas,
                      })
                    }
                  })
                });

                this.arr2.forEach(tmp => {
                  this.listSubJurusan.forEach(subjur => {
                    if (tmp.iddetailjurusan === subjur.id) {
                      this.arr3.push({
                        'idtingkat': tmp.idtingkat,
                        'tingkat': tmp.tingkat,
                        'idjurusan': tmp.idjurusan,
                        'jurusan': tmp.jurusan,
                        'iddetailjurusan': tmp.iddetailjurusan,
                        'detailjurusan': subjur.sub,
                        'walikelas': tmp.walikelas,
                      });
                    }
                  });
                });
                this.dataSource = new MatTableDataSource(this.arr3);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.obs = this.dataSource.connect();
              }, 500);
            });
          })
        })
      });
    });
    this.closeSpinner();
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  async getJurusanKelas() {
    this.db.list(this.mainDb + '/jurusan/').snapshotChanges().subscribe(items => {
      this.listJurusan = [];
      items.forEach(item => {
        this.listJurusan.push(getJurusan(item.payload));
        // console.log(this.listJurusan);
      });
    });

    this.db.list(this.mainDb + '/detailJurusan/').snapshotChanges().subscribe(items => {
      this.listSubJurusan = [];
      items.forEach(item => {
        let idJurusan = item.key;
        this.db.list(this.mainDb + '/detailJurusan/' + idJurusan + '/').snapshotChanges().subscribe(datas => {
          datas.forEach(data => {
            // console.log(data.key);
            this.listSubJurusan.push(getSubJurusan(idJurusan, data.key, data.payload.val()['sub']));
            // console.log(this.listSubJurusan);
          });
        });
      });
    });
  }

  getRecord(item) {
    this.dialogRef = this.dialog.open(Dialogtambahkelas, {
      panelClass: 'dialog',
      width: '30%',
      hasBackdrop: true,
      data: {
        action: 'edit',
        UserData: item,
        guru: this.listguru,
        subJurusan: this.listSubJurusan,
        jurusan: this.listJurusan,
        tingkat: this.listTingkat,
      }
    });
  }

  tambahkelas(): void {
    this.dialogRef = this.dialog.open(Dialogtambahkelas, {
      panelClass: 'dialog',
      width: '30%',
      hasBackdrop: true,
      data: {
        action: 'new',
        guru: this.listguru,
        subJurusan: this.listSubJurusan,
        jurusan: this.listJurusan,
        tingkat: this.listTingkat,
      }
    });
  }

  jadwal(row): void {
    this.dialogRef = this.dialog.open(DialogJadwal, {
      panelClass: 'dialog',
      width: '75%',
      hasBackdrop: true,
      data: {
        action: 'jadwal',
        UserData: row,
        // guru: this.listguru,
        // subJurusan: this.listSubJurusan,
        // jurusan: this.listJurusan,
        // tingkat:this.listTingkat,
      }
    });
  }

  siswa(item) {
    this.dialogRef = this.dialog.open(DialogSiswa, {
      panelClass: 'dialog',
      width: '75%',
      height: '90%',
      hasBackdrop: true,
      data: {
        action: 'siswa',
        UserData: item,
      }
    });
  }

  hapus(item) {
    var r = confirm("Yakin ingin menghapus ?");
    if (r == true) {
      console.log(item);
      this.loadSpinner();
      this.db.database.ref(this.mainDb + '/kelas/' + item.idtingkat + '/' + item.idjurusan).remove().then(() => {
        this.closeSpinner();
      });
    }
  }
}

@Component({
  selector: 'siswa',
  templateUrl: 'daftarsiswa/daftar-siswa.html',
  styleUrls: ['daftarsiswa/daftar-siswa.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DialogSiswa {
  listmenu: siswa[] = [];
  dataSource: MatTableDataSource<siswa>;
  obs: Observable<any>;
  mainDb = environment.mainDb;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(
    public dialogRef: MatDialogRef<Dialogtambahkelas>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;


  ngOnInit(): void {
    console.log(this.data);
    // idtingkat: "-M0m3yE9QC-EtIGf2WGh"
    // tingkat: "XII"
    // idjurusan: "123121231"
    // jurusan: "Multimedia (MM)"
    // iddetailjurusan: "1231212311"
    // detailjurusan: "MM-A"
    this.loadsiswa();
  }

  loadsiswa() {
    this.loadSpinner();
    this.db.list(this.mainDb + '/siswa').snapshotChanges().subscribe(res => {
      this.listmenu = [];
      res.forEach(result => {
        this.listmenu.push(getListMhs(
          result.key,
          result.payload.val()['jadwal'],
          result.payload.val()['nama'],
          result.payload.val()['nilai'],
          result.payload.val()['tingkat'],
          result.payload.val()['jurusan'],
          result.payload.val()['detailJurusan'],
          result.payload.val()['profile'],
          result.payload.val()['alamat'],
          result.payload.val()['bio'],
          result.payload.val()['gender'],
          result.payload.val()['tempatlahir'],
          result.payload.val()['absensi'],
          result.payload.val()['spp'],
          result.payload.val()['token'],
          new Date(Number(result.payload.val()['tgllahir']))
        ));
      });
      // console.log(this.listmenu);
      setTimeout(() => {
        let temp = this.listmenu.filter(item =>
          item.tingkat === this.data.UserData.idtingkat
          && item.jurusan === this.data.UserData.idjurusan
          && item.detailJurusan === this.data.UserData.iddetailjurusan);
        this.dataSource = new MatTableDataSource(temp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.obs = this.dataSource.connect();
        this.closeSpinner();
      }, 500);
    })
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }
}

@Component({
  selector: 'tambahkelas',
  templateUrl: 'tambahkelas/tambahkelas.component.html',
  styleUrls: ['tambahkelas/tambahkelas.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class Dialogtambahkelas {
  modelKelas: any = [];
  kelasForm: FormGroup;

  tmpSubJurusan: any = [];

  listJurusan: iJurusan[] = [];
  listSubJurusan: iSubJurusan[] = [];
  listGuru: iGuru[] = [];
  listTingkat: iTingkat[] = [];

  filteredOptions: Observable<string[]>;
  value2: any;
  flag: boolean;

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  currentUnixTime = Date.now();
  mainDb = environment.mainDb;
  item: any = [];
  constructor(
    public dialogRef: MatDialogRef<Dialogtambahkelas>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;


  ngOnInit(): void {
    this.kelasForm = new FormGroup({
      kelas: new FormControl(''),
      jurusan: new FormControl(''),
      detailkelas: new FormControl(''),
      walikelas: new FormControl(''),
    });
    console.log(this.data);
    this.listJurusan = this.data.jurusan;
    this.listSubJurusan = this.data.subJurusan;
    this.listGuru = this.data.guru;
    this.listTingkat = this.data.tingkat;
    if (this.data.action === 'new') {
      // this.flag = true;
      // this.title = 'Siswa Baru';
      this._filteredOptions();
    }
    else {
      this._filteredOptions();
      this.tmpSubJurusan = [];
      this.modelKelas = this.data.UserData;
      this.modelKelas.kelas = this.data.UserData.idtingkat;
      this.modelKelas.jurusan = this.data.UserData.idjurusan;
      this.modelKelas.detailkelas = this.data.UserData.iddetailjurusan;
      // this.modelKelas.walikelas = this.data.UserData.walikelas.nama;

      this.tmpSubJurusan = this.data.subJurusan.filter((item) => {
        return item.idJur === this.data.UserData.jurusan;
      });
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  displayFn(e?: any): string | undefined {
    if (!e) {
      return '';
    }
    return e.nama;
  }

  _filteredOptions() {
    this.filteredOptions = this.kelasForm.controls['walikelas'].valueChanges
      .pipe(
        startWith(''),
        map(item => this._filter(item)),
        tap(item => this.value2 = item));
  }

  _filter(value: any): any[] {
    console.log(value);
    const filterValue = value.toString().toLowerCase();
    const results = this.listGuru.filter(option => option.nama.toString().toLowerCase().includes(filterValue));
    // console.log(results.length);
    if (results.length > 0) {
      this.flag = true;
      // console.log(this.flag);
    } else {
      this.flag = false;
      // console.log(this.flag);
    }
    return results.length ? results : ['No data'];
  }

  _allowSelection(option: string): { [className: string]: boolean } {
    return {
      'no-data': option === 'No data',
    };
  }

  async JurusanChange(e) {
    this.tmpSubJurusan = [];
    let tmp = e.value;
    this.tmpSubJurusan = this.listSubJurusan.filter((item) => {
      return item.idJur === tmp;
    });
    console.log(this.tmpSubJurusan);
  }

  simpan() {
    // console.log(this.modelKelas);
    // this.loadSpinner();
    if (this.data.action === 'new') {
      this.db.database.ref(this.mainDb + '/kelas/' + this.modelKelas.kelas + '/' + this.modelKelas.jurusan + '/' + this.modelKelas.detailkelas).set(
        {
          'walikelas': this.modelKelas.walikelas
        }).then(() => {
          this.dialogRef.close();
          this.closeSpinner();
        });
    } else {
      console.log(this.modelKelas);
    }
  }
}

function GetListMenu(data): iGuru {
  return {
    id: data.key,
    jabatan: data.val()['jabatan'],
    image: data.val()['image'],
    nama: data.val()['nama'],
    nip: data.val()['nip'],
  };
}

function getJurusan(data): iJurusan {
  return {
    id: data.key,
    nama: data.val()['nama'],
    deskripsi: data.val()['deskripsi'],

  };
}

function getSubJurusan(idJur: string, id: string, sub: string): iSubJurusan {
  return {
    idJur: idJur,
    id: id,
    sub: sub
  };
}

function getListData(idkelas: string, jurusan: string, detailJurusan: string, walikelas: any): MenuData {
  return {
    idkelas: idkelas,
    jurusan: jurusan,
    detailJurusan: detailJurusan,
    walikelas: walikelas
  }
}

function getListMhs(id: string, jadwal: any[], nama: string, nilai: iNilai[], tingkat: string, jurusan: string, detailJurusan: string,
  profile: any, alamat: string, bio: string, gender: string, tempatlahir: string, absensi: any[], spp: any[], token: any[], tgllahir: any): siswa {
  return {
    id: id,
    jadwal: jadwal,
    nama: nama,
    nilai: nilai,
    tingkat: tingkat,
    jurusan: jurusan,
    detailJurusan: detailJurusan,
    profile: profile,
    alamat: alamat,
    bio: bio,
    gender: gender,
    tempatlahir: tempatlahir,
    absensi: absensi,
    spp: spp,
    token: token,
    tgllahir: tgllahir
  };
}

function getTingkatan(data): iTingkat {
  return {
    id: data.key,
    tingkat: data.val()['tingkat']
  }
}

export interface MenuData {
  idkelas: string;
  jurusan: string;
  detailJurusan: string;
  walikelas: any[];
}

export interface iGuru {
  id: string;
  jabatan: string;
  image: string;
  nama: string;
  nip: string;
}

export interface iJurusan {
  id: string;
  nama: string;
  deskripsi: string;
}

export interface iSubJurusan {
  idJur: string;
  id: string,
  sub: string
}
export interface iTingkat {
  id: string,
  tingkat: string,
}

export interface siswa {
  id: string;
  jadwal: any[];
  nama: string;
  nilai: iNilai[];
  tingkat: string;
  jurusan: string;
  detailJurusan: string
  profile: string;
  alamat: string;
  bio: string;
  gender: string;
  tempatlahir: string;
  absensi: any[];
  spp: any[];
  token: any[];
  tgllahir: any;
}

export interface iNilai {
  jenis: string;
  semester: string;
  nilai: any[];
}


export const COMPONENT_LIST = [
  KelasComponent,
  Dialogtambahkelas,
  DialogJadwal,
  COMPONENT_SUBLIST,
  DialogSiswa,
];
