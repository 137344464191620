import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MatInputModule } from '@angular/material/input';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { environment } from 'environments/environment';

declare var jQuery: any;


@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: fuseAnimations
})
export class Profile implements OnInit {
  ref: any = [];
  items: any[];
  selectedRowIndex = [];
  displayedColumns = ['id', 'category'];
  loadRef: any = [];
  dialogRef: any = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;
  listmenu: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadSpinner();
    this.db.list(this.mainDb + '/profile')
      .snapshotChanges()
      .subscribe(items => {
        this.listmenu = [];
        items.forEach(item => {
          this.listmenu.push(GetListMenu(item.payload));
        });
        this.dataSource = new MatTableDataSource(this.listmenu);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.closeSpinner();
      });
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }


  getRecord(row) {

    this.dialogRef = this.dialog.open(DialogProfile, {
      data: row,
    });
  }

  addProduct() {
    this.dialogRef = this.dialog.open(DialogProfile, {
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
@Component({
  selector: 'profile-dialog',
  templateUrl: 'profile-dialog.html',
  styleUrls: ['profile.component.scss']
})
export class DialogDaftarCategory {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = [];
  modelDosen: any = [];
  ListImage = [];
  imageChangedEvent: any = '';
  gambar: any;
  croppedImage: any = '';
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,

    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
  ngOnInit() {
    if (this.data !== null) {
      this.gambar = this.data.image;
    }
  }
  openfile() {
    jQuery('#imgupload').trigger('click');
  }
  getFileAttachment(event: any) {
    this.imageChangedEvent = event;

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}

@Component({
  selector: 'profile-dialog',
  templateUrl: 'profile-dialog.html',
  styleUrls: ['profile.component.scss']
})
export class DialogProfile {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  modelDosen: any = [];
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;
  constructor(
    public dialogRef: MatDialogRef<DialogProfile>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  ngOnInit() {
    if (this.data !== null) {
      this.flag = true;
      this.gambar = this.data.image;
      this.key = this.data.id;
      this.oldGambar = this.data.filename;
      this.modelDosen = this.data;

    } else {
      this.flag = false;
      this.gambar = 'https://firebasestorage.googleapis.com/v0/b/pasarpagisegar.appspot.com/o/empty.png?alt=media&token=3e5b1264-f831-4c28-9d61-2956fbcf1a42';
      this.kategori = '';
    }

  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  editCategory() {
    this.loadSpinner();
    const file = this.croppedImage;
    const filePath = 'profile/' + this.modelDosen.judul + '.webp';
    const fileRef = this.storage.ref(filePath);
    if (this.flag) {
      if (this.croppedImage !== '') {
        this.storage.upload(filePath, this.croppedImage);
        const task = this.storage.upload(filePath, this.croppedImage);
        task.snapshotChanges().pipe(
         finalize(() => {
           this.downloadURL = fileRef.getDownloadURL()
           this.downloadURL.subscribe(url => {
             if (url) {
               this.db.database.ref(this.mainDb + '/profile/0').set(
                 {
                   'judul': this.modelDosen.judul.toString(),
                   'isi': this.modelDosen.isi,
                   'filename': this.data.filename,
                   'image': url,
                 }
               ).then(() => {
                 this.closeSpinner();
                 if (this.oldGambar !== filePath) {
                   this.storage.ref(this.oldGambar).delete();
                 }
                 this.dialogRef.close();
               })
             }
           })
         })
        )
          .subscribe()
      } else {

        this.db.database.ref(this.mainDb + '/profile/0').set(
          {
            'judul': this.modelDosen.judul.toString(),
            'isi': this.modelDosen.isi,
            'filename': this.data.filename,
            'image': this.data.image,
          }).finally(() => {
            this.closeSpinner();
            this.dialogRef.close();
          })
      }

    } else {
      const fileRefr = this.storage.ref(filePath.toString());
      const task = this.storage.upload(filePath, this.croppedImage);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRefr.getDownloadURL()
          this.downloadURL.subscribe(url => {
            if (url) {
              this.db.database.ref(this.mainDb + '/profile/').push(
                {
                  'nama': this.modelDosen.nama.toString(),
                  'nip': this.modelDosen.nip !== undefined ? this.modelDosen.nip.toString() : "",
                  'filename': filePath.toString(),
                  'jabatan': this.modelDosen.jabatan !== undefined ? this.modelDosen.jabatan.toString() : "",
                  'image': url,
                }
              ).then(() => {
                this.closeSpinner();
                this.dialogRef.close();
              })
            }
          })
        })
      )
        .subscribe()
    }
  }

  getFileAttachment(event: any) {
    this.imageChangedEvent = event;
    //   if (event.target.files && event.target.files[0]) {
    //     const file = event.target.files[0];

    //     const reader = new FileReader();
    //     reader.onload = e => this.gambar = reader.result;
    //     reader.readAsDataURL(file);
    // }
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.file;
    this.gambar = event.base64;

  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}

function GetListMenu(data): MenuData {

  return {
    id: data.key,
    image: data.val()['image'],
    isi: data.val()['isi'],
    judul: data.val()['judul'],
    filename: data.val()['filename'],
  };
}

export interface MenuData {
  id: string;
  image: string;
  isi: string;
  judul: string;
  filename: string;
}



export const COMPONENT_LIST = [
  Profile,
  DialogProfile,
]
