export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'PENGUMUMAN': {
                'TITLE': 'Pengumuman',
                'BADGE': '25'
            },
            'INFO': {
                'TITLE': 'Info',
                'BADGE': '25'
            },
            'PPDB': {
                'TITLE': 'PPDB',
                'BADGE': '25'
            },
            'NILAI': {
                'TITLE': 'Nilai',
                'BADGE': '25'
            },
            'KALENDAR': {
                'TITLE': 'Kalender',
                'BADGE': '25'
            },
            'PROFILE': {
                'TITLE': 'Profile Sekolah',
                'BADGE': '25'
            },
            'DOSEN': {
                'TITLE': 'Daftar Guru',
                'BADGE': '25'
            },
            'MAHASISWA': {
                'TITLE': 'Daftar Siswa',
                'BADGE': '25'
            },
            'KELAS': {
                'TITLE': 'Kelas',
                'BADGE': '25'
            },
            'JURUSAN' : {
                'TITLE': 'Jurusan',
                'BADGE': '25'
            },
            'PENGATURAN':{
                'TITLE': 'Pengaturan',
                'BADGE': '25'
            },
            'JADWAL': {
                'TITLE': 'PBM',
                'BADGE': '25'
            },
            'MATPEL': {
                'TITLE': 'Mata Pelajaran',
                'BADGE': '25'
            },
            'PROGRAM':{
                'TITLE': 'Program Sekolah',
                'BADGE': '25'
            },
            'KELUAR': {
                'TITLE': 'Keluar',
                'BADGE': '25'
            }
        }
    }
};
