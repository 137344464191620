import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-jadwalkuliah',
  templateUrl: './jadwalkuliah.component.html',
  styleUrls: ['./jadwalkuliah.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class JadwalkuliahComponent implements OnInit {
  displayedColumns = ['jam',  'matpel', 'guru'];
  // 'jurusan', 'kelas',
  dataSource: MatTableDataSource<iJadwal>;
  listJadwal: iJadwal[] = [];
  finaljadwal:iJadwal[]=[];
  /**
 * Constructor
 *
 * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
 */

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  mainDb = environment.mainDb;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    public _MatDialog: MatDialog,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  ngOnInit() {
   this.loaddata();
  }

  loaddata(){
    this.db.list(this.mainDb + '/jadwal/').snapshotChanges().subscribe(kelass=>{
      this.listJadwal = [];
      kelass.forEach(kelas=>{
        let idKelas = kelas.key;
        this.db.list(this.mainDb + '/jadwal/' + idKelas + '/').snapshotChanges().subscribe(jurusans=>{
          jurusans.forEach(jurusan=>{
            let idJurusan = jurusan.key;
            this.db.list(this.mainDb + '/jadwal/' + idKelas + '/' + idJurusan + '/').snapshotChanges().subscribe(detailJurusans=>{
              detailJurusans.forEach(detailJurusan=>{
                let iddetailJurusan = detailJurusan.key;
                this.db.list(this.mainDb + '/jadwal/' + idKelas + '/' + idJurusan + '/' + iddetailJurusan + '/').snapshotChanges().subscribe(haris=>{
                  haris.forEach(hari=>{
                    let idHari = hari.key;
                    this.db.list(this.mainDb + '/jadwal/' + idKelas + '/' + idJurusan + '/' 
                    + iddetailJurusan + '/' + idHari + '/').snapshotChanges().subscribe(jadwals=>{
                      jadwals.forEach(jadwal=>{
                        let idJadwal = jadwal.key;
                        // console.log(jadwal.payload.val());
                        this.listJadwal.push(getJadwal(idKelas, idJurusan, iddetailJurusan,
                          idHari, idJadwal, jadwal.payload.val()['guru'], jadwal.payload.val()['jam'], jadwal.payload.val()['matapelajaran']));
                        // console.log(this.listJadwal);
                      });
                      setTimeout(() => {
                        this.finaljadwal = this.listJadwal.filter(item=>{
                          return item.idHari === '1' && item.idDetailJurusan === '1231212311';
                        })
                        // console.log(item);
                        this.dataSource = new MatTableDataSource(this.finaljadwal);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                      }, 500);
                    })
                  })
                });
              })
            })
          })
        })
      });
    })
  }

  addData() {
    const dialogRef = this._MatDialog.open(tambahdata, {
      width: '500px',
      panelClass: 'dialog',
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
    // var jurusan = 'MI';
    // var semester = 'I';
    // var kelas = 'MI-A';
    // var hari = 'SELASA';
    // let query = this.db.database.ref('/jadwal/' + jurusan + '/' + semester + '/' + kelas + '/' + hari + '/');
    // query.push({
    //   'kode': 'MI1PB1',
    //   'matkul': 'MANAJEMEN, ORGANISASI DAN PROSES BISNIS',
    //   'sks': '2',
    //   'jammasuk': '08:45',
    //   'jamselesai': '09:45',
    //   'dosen': 'Ibnu Husein',
    //   'ruangan': 'L2T1',
    // });
  }

}


@Component({
  selector: 'sample2-dialog',
  templateUrl: 'tambahjadwal/buatjadwal.html',
  styleUrls: ['tambahjadwal/buatjadwal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class tambahdata {
  listJurusan: iJurusan[] = [];
  listKelas: iKelas[] = [];
  listRuangan: iRuangan[] = [];

  matkul: any = [];
  tmpKelas: any = [];
  mainDb = environment.mainDb;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    public _MatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit(): void {
    console.log(this.data);
    this.data.forEach(el => {
      this.matkul.push({
        'kode': el.kode,
        'nama': el.nama
      });
    });
    console.log(this.matkul);
    this.db.list(this.mainDb + '/ruangan/').snapshotChanges().subscribe(items => {
      this.listRuangan = [];
      items.forEach(item => {
        this.listRuangan.push(getRuangan(item.key, item.payload.val()['nama'], item.payload.val()['lantai']));
      });
    });

    this.db.list(this.mainDb + '/detailjurusan/').snapshotChanges().subscribe(kelas => {
      this.listKelas = [];
      kelas.forEach(data => {
        var idJur = data.key;
        this.db.list(this.mainDb + '/detailjurusan/' + idJur + '/').snapshotChanges().subscribe(items => {
          items.forEach(item => {
            this.listKelas.push(getKelas(idJur, item.key, item.payload.val()['nama']));
          });
        });
      });
    });

    this.db.list(this.mainDb + '/jurusan/').snapshotChanges().subscribe(keys => {
      this.listJurusan = [];
      keys.forEach(key => {
        this.listJurusan.push(getJurusan(key.key, key.payload.val()['nama']));
      });
    });
  }

  JurusanChange(e) {
    let tmp = e.value;
    console.log(e);
    console.log(this.listKelas);
    this.tmpKelas = this.listKelas.filter((item) => {
      return item.idJur === tmp;
    });
    console.log(this.tmpKelas);
  }
}

export interface iJurusan {
  id: string,
  nama: string
}

export interface iKelas {
  idJur: string;
  id: string,
  nama: string
}

export interface iRuangan {
  id: string;
  nama: string;
  lantai: string;
}

export interface iJadwal {
  idKelas: string;
  idJurusan: string;
  idDetailJurusan: string;
  idHari:string;
  idJadwal: string;
  guru:string;
  jam:any;
  MataPelajaran:string;
}

function getJadwal(idKelas: string, idJurusan: string, 
  idDetailJurusan: string, idHari:string, idJadwal: string, guru:string, jam:any, MataPelajaran:string): iJadwal {
  return {
    idKelas:idKelas,
    idJurusan:idJurusan,
    idDetailJurusan:idDetailJurusan,
    idHari:idHari,
    idJadwal:idJadwal,
    guru:guru,
    jam:jam,
    MataPelajaran:MataPelajaran
  } 
}

function getRuangan(id: string, nama: string, lantai: string): iRuangan {
  return {
    id: id,
    nama: nama,
    lantai: lantai
  };
}


function getJurusan(id: string, nama: string): iJurusan {
  return {
    id: id,
    nama: nama
  };
}

function getKelas(idJur: string, id: string, nama: string): iKelas {
  return {
    idJur: idJur,
    id: id,
    nama: nama
  };
}

export const COMPONENT_LIST = [
  JadwalkuliahComponent,
  tambahdata
];

