import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from '../i18n/en';
import { locale as turkish } from '../i18n/tr';
import { Observable, of } from 'rxjs';
import { finalize, subscribeOn, startWith, tap, delay } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import * as moment from 'moment';

declare var jQuery: any;

@Component({
  selector: 'jadwal',
  templateUrl: 'jadwal.component.html',
  styleUrls: ['jadwal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DialogJadwal implements OnInit {
  Listdate: any = [];
  displayedColumns = ['jam',  'matpel', 'guru'];
  dataSource: MatTableDataSource<any>;
  ModelJadwal:any = [];
  jadwalForm:FormGroup;

  listJadwal: iJadwal[] = [];
  finaljadwal:iJadwal[]=[];
  tmphari:string ='';

  SelectDate:any=[];

  AngkaHari:string = '';
  _dialogRef:any=[];
  isLoading = true;
  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */


  constructor(
    public dialogRef: MatDialogRef<DialogJadwal>,
    @Inject(MAT_DIALOG_DATA) public data: any = '',
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    // this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit(): void {
    console.log(this.data);
    this.loaddata();
    // this.date = moment().months();
    this.Listdate = moment.weekdays();
    console.log(this.Listdate);
    this.ModelJadwal.HariIni = moment().format('dddd');
    this.AngkaHari = moment().format('d').toString();

  }

  loaddata(){
    this.listJadwal = [];
    this.db.list('jadwal/').snapshotChanges().subscribe(kelass=>{
      this.listJadwal = [];
      kelass.forEach(kelas=>{
        let idKelas = kelas.key;
        this.db.list('jadwal/' + idKelas + '/').snapshotChanges().subscribe(jurusans=>{
          jurusans.forEach(jurusan=>{
            let idJurusan = jurusan.key;
            this.db.list('jadwal/' + idKelas + '/' + idJurusan + '/').snapshotChanges().subscribe(detailJurusans=>{
              detailJurusans.forEach(detailJurusan=>{
                let iddetailJurusan = detailJurusan.key;
                this.db.list('jadwal/' + idKelas + '/' + idJurusan + '/' + iddetailJurusan + '/').snapshotChanges().subscribe(haris=>{
                  haris.forEach(hari=>{
                    let idHari = hari.key;
                    this.db.list('jadwal/' + idKelas + '/' + idJurusan + '/' 
                    + iddetailJurusan + '/' + idHari + '/').snapshotChanges().subscribe(jadwals=>{
                      jadwals.forEach(jadwal=>{
                        let idJadwal = jadwal.key;
                        // console.log(jadwal.payload.val());
                        this.listJadwal.push(getJadwal(idKelas, idJurusan, iddetailJurusan,
                          idHari, idJadwal, jadwal.payload.val()['guru'], jadwal.payload.val()['jam'], jadwal.payload.val()['matapelajaran']));
                        // console.log(this.listJadwal);
                      });
                      setTimeout(() => {
                        this.finaljadwal = this.listJadwal.filter(item=>{
                          return item.idHari === this.AngkaHari && item.idKelas === this.data.UserData.idtingkat && item.idJurusan === this.data.UserData.idjurusan && item.idDetailJurusan === this.data.UserData.iddetailjurusan;
                        })
                        // console.log(this.finaljadwal);
                        of(this.finaljadwal).pipe(delay(800))
                        .subscribe(data => {
                          this.isLoading = false;
                          this.dataSource = new MatTableDataSource(data);
                          this.dataSource.paginator = this.paginator;
                          this.dataSource.sort = this.sort;
                        }, error => this.isLoading = false);
                        // this.dataSource = new MatTableDataSource(this.finaljadwal);
                        // this.dataSource.paginator = this.paginator;
                        // this.dataSource.sort = this.sort;
                      }, 500);
                    })
                  })
                });
              })
            })
          })
        })
      });
    })
  }

  async onPilChange(e){
    this.SelectDate = [];
    this.tmphari = '';
    // console.log(e.value);
    this.tmphari = moment().day(e.value.toString()).format('d');
    console.log(this.tmphari);
    // hari = 0;
  //  console.log(this.listJadwal);
   this.SelectDate = this.listJadwal.filter(item=>{
    return item.idHari === this.tmphari && item.idKelas === this.data.UserData.idtingkat && item.idJurusan === this.data.UserData.idjurusan && item.idDetailJurusan === this.data.UserData.iddetailjurusan;
   });

   of(this.SelectDate).pipe(delay(800))
   .subscribe(data => {
     this.isLoading = false;
     this.dataSource = new MatTableDataSource(data);
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
   }, error => this.isLoading = false);
  }

  tambahjadwal(): void {
    this._dialogRef = this.dialog.open(DialogtambahJadwal, {
      panelClass: 'dialog',
      width: '30%',
      hasBackdrop: true,
      data: {
        action: 'new',
        UserData: this.data.UserData,
        Hari : this.Listdate,
      }
    });
  }
}

@Component({
  selector: 'tambahjadwal',
  templateUrl: 'tambahjadwal/tambahjadwal.component.html',
  styleUrls: ['tambahjadwal/tambahjadwal.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DialogtambahJadwal {
  jadwalForm:FormGroup;
  ModelJadwal:any=[];
  listmatpel:iMatpel[] = [];
  listguru: iGuru[] = [];
  currentUnixTime = Date.now();

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  constructor(
    public dialogRef: MatDialogRef<DialogtambahJadwal>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;


  ngOnInit(): void {
    console.log(this.data);
    this.loadMatpel();
    this.loadGuru();
    this.jadwalForm = new FormGroup({
      hari: new FormControl(''),
      mulai: new FormControl(''),
      selesai: new FormControl(''),
      matpel: new FormControl(''),
      guru: new FormControl(''),
    });
  }

  
  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  loadMatpel(){
    this.db.list('/masterPelajaran/').snapshotChanges().subscribe(items=>{
      this.listmatpel = [];
      items.forEach(item=>{
        this.listmatpel.push(getMatpel(item.payload));
        console.log(this.listmatpel);
      })
    });
  }

  loadGuru() {
    this.db.list('/pengajar')
      .snapshotChanges()
      .subscribe(items => {
        this.listguru = [];
        items.forEach(item => {
          this.listguru.push(GetListMenu(item.payload));
          // console.log(this.listguru);
        });
      });
    }

  simpan(){
    // console.log(this.jadwalForm.value);
    this.loadSpinner();
    var _moment = moment();
    var tmp = _moment.day(this.ModelJadwal.hari).format('d');
    // console.log(tmp);

    this.db.database.ref('jadwal/' + this.data.UserData.idtingkat + '/' + this.data.UserData.idjurusan + '/' + 
    this.data.UserData.iddetailjurusan + '/' + tmp + '/' + this.currentUnixTime).set({
      'guru': this.ModelJadwal.guru !== undefined ? this.ModelJadwal.guru:'',
      'jam': this.ModelJadwal.mulai + ' - ' + this.ModelJadwal.selesai,
      'matapelajaran': this.ModelJadwal.matpel !== undefined ? this.ModelJadwal.matpel :''
    }).then(()=>{
      this.closeSpinner();
      this.dialogRef.close();
    });
  }
}

export interface iJadwal {
  idKelas: string;
  idJurusan: string;
  idDetailJurusan: string;
  idHari:string;
  idJadwal: string;
  guru:string;
  jam:any;
  MataPelajaran:string;
}

export interface iMatpel {
  id: string;
  kode: string;
  MataPelajaran:string;
}

export interface iGuru {
  id: string;
  jabatan: string;
  image: string;
  nama: string;
  nip: string;
}

function GetListMenu(data): iGuru {
  return {
    id: data.key,
    jabatan: data.val()['jabatan'],
    image: data.val()['image'],
    nama: data.val()['nama'],
    nip: data.val()['nip'],
  };
}

function getMatpel(data): iMatpel {
  return {
    id:data.key,
    kode:data.val()['kodePelajaran'],
    MataPelajaran:data.val()['nama'],
  }
}

function getJadwal(idKelas: string, idJurusan: string, 
  idDetailJurusan: string, idHari:string, idJadwal: string, guru:string, jam:any, MataPelajaran:string): iJadwal {
  return {
    idKelas:idKelas,
    idJurusan:idJurusan,
    idDetailJurusan:idDetailJurusan,
    idHari:idHari,
    idJadwal:idJadwal,
    guru:guru,
    jam:jam,
    MataPelajaran:MataPelajaran
  } 
}

export const COMPONENT_SUBLIST = [
  DialogtambahJadwal,
  DialogJadwal
];
