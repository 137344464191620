import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import 'hammerjs';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { DaftarMenuModule } from 'app/main/daftarmenu/daftarmenu.module';
import { DaftarDosenModule } from 'app/main/dosen/daftardosen.module';
import { LoginModule } from 'app/main/login/login.module';
import { AcademyModule } from 'app/main/academy/academy.module';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { ProfileModule } from 'app/main/profile/profile.module';
import { MahasiswaModule } from './main/mahasiswa/mahasiswa.module';
import { JadwalkuliahModule } from './main/jadwalkuliah/jadwalkuliah.module';
import { KelasModule } from './main/kelas/kelas.module';
import { JurusanModule } from './main/jurusan/jurusan.module';
import { InfoModule } from './main/info/info.module';
import { ProgramModule } from './main/program/program.module';
import { MatapelajaranModule } from './main/matapelajaran/matapelajaran.module';
import { PpdbModule } from './main/ppdb/ppdb.module';

import { AngularEditorModule } from '@kolkov/angular-editor';

import {
    OwlDateTimeModule,
    OwlNativeDateTimeModule
} from 'ng-pick-datetime';


const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        AngularFireStorageModule,
        TranslateModule.forRoot(),

        // Material moment date module
        AngularEditorModule,
        MatMomentDateModule,

        OwlDateTimeModule,
        OwlNativeDateTimeModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        LoginModule,
        DaftarMenuModule,
        AcademyModule,
        DaftarDosenModule,
        ProfileModule,
        MahasiswaModule,
        JadwalkuliahModule,
        KelasModule,
        JurusanModule,
        InfoModule,
        ProgramModule,
        MatapelajaranModule,
        PpdbModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
