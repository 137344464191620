import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { from, Observable, throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  BaseURL = environment.BaseUrl;
  constructor(
    private http: HttpClient
  ) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }

  confirmToken(token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'key=AAAAfizz9sU:APA91bFpyttTiE02LLitKYib1wsMGdLjoDocBBi5QEH8l9CmHKCcTSxSfapRgIv3VsPR4JFy3erENuFQTMhcRmRB02twpPVgm6uwB-LssD-XsQRLTR-KojgzWLo7fklkmNk32-Cc4gJw'
      })
    };

    return this.http.post(this.BaseURL, token, httpOptions).pipe(
      catchError(this.handleError('tokenFcm', token))
    );
  }
}
