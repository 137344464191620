import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError, MatChipInputEvent } from '@angular/material';

declare var jQuery: any;
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiService } from 'app/services/api.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'daftarmenu',
  templateUrl: './daftarmenu.component.html',
  styleUrls: ['./daftarmenu.component.scss'],
  animations: fuseAnimations
})
export class DaftarMenu implements OnInit {
  ref: any = [];
  items: any[];
  selectedRowIndex = [];
  displayedColumns = ['id', 'category'];
  loadRef: any = [];
  dialogRef: any = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  listmenu: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;
  listToken: any = [];
  listTujuan: iTujuan[] = [];
  mainDb = environment.mainDb;
  msgCOnfirm = {
    "notification": {
      // "image": "https://firebasestorage.googleapis.com/v0/b/royyan-48da2.appspot.com/o/logo.jpg?alt=media&token=f10da88c-c0a7-4245-a305-669f64ed400a",
      "color": "#990000",
      "click_action": "FLUTTER_NOTIFICATION_CLICK"
    },
    "data": {
      "keyname": "any value"
    },
    // "to": "dn87btsM7Bg:APA91bHlYCHdJJNXVJcoWsq_jUtcumE8KJ3M2ir-UQweGSbeXouEFhHQMjfjn4SLgkiOY06Bar9Q0a4IWiZGUClZHJaK6nF7787eGJRjay6f3FNUIvsD7RdiIDXM_RibXb1rb8YTuQaG"
  };

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    http: HttpClient,
    private API: ApiService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadSpinner();
    this.db.list(this.mainDb + '/pengumuman')
      .snapshotChanges()
      .subscribe(items => {
        this.listmenu = [];
        items.forEach(item => {
          this.listmenu.push(GetListMenu(item.payload));
        });
        this.dataSource = new MatTableDataSource(this.listmenu);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.closeSpinner();
      });

    this.loadToken();
  }

  loadToken() {
    this.db.list(this.mainDb + '/siswa/').snapshotChanges().subscribe(
      uids => {
        this.listToken = [];
        uids.forEach(uid => {
          var id = uid.key;
          this.db.list(this.mainDb + '/siswa/' + id + '/token/').snapshotChanges().subscribe(datas => {
            datas.forEach(data => {
              this.listToken.push(data.payload.val()['token']);
              // console.log(this.listToken);
            })
          });
        });
      });
  }

  // openDialog(): void {
  //   let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
  //     width: '250px',
  //     data: { name: this.name, animal: this.animal }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.animal = result;
  //   });
  // }
  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.judul + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref(this.mainDb + '/pengumuman/' + item.id).remove();
      this.storage.ref(item.filename).delete();
      this.closeSpinner();
    }
  }

  getRecord(row) {
    this.dialogRef = this.dialog.open(DialogDaftarmenu, {
      data: row,
      panelClass: 'dialog',
      width: '50%',
      height: '90%',
      hasBackdrop: true,
    });
  }
  // {{(item.judul.length > 6)? (item.judul | slice:0:20)+'..':(item.judul)}}

  getList(item) {
    this.msgCOnfirm["registration_ids"] = this.listToken;
    this.msgCOnfirm.notification["idpengumunan"] = item.id;
    this.msgCOnfirm.notification["image"] = item.image;
    this.msgCOnfirm.notification["title"] = item.judul;
    this.msgCOnfirm.notification["priority"] = "high";
    this.msgCOnfirm.notification["body"] = item.deskripsi.toString().slice(0, 100).concat('...');

    var answer = window.confirm("Apakah anda yakin ingin mengirim keseluruh siswa ?")
    if (answer) {
      this.API.confirmToken(this.msgCOnfirm).subscribe(result => {
        console.log(result);
      });
    }
  }

  addProduct() {
    this.dialogRef = this.dialog.open(DialogDaftarmenu, {
      panelClass: 'dialog',
      width: '50%',
      height: '90%',
      hasBackdrop: true,
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

@Component({
  selector: 'daftarcategory-dialog',
  templateUrl: 'daftarcategory-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogDaftarCategory {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = [];
  ListImage = [];
  imageChangedEvent: any = '';
  gambar: any;
  croppedImage: any = '';
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,

    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
  ngOnInit() {
    if (this.data !== null) {
      this.gambar = this.data.image;
    }
  }
  openfile() {
    jQuery('#imgupload').trigger('click');
  }
  getFileAttachment(event: any) {
    this.imageChangedEvent = event;

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}

@Component({
  selector: 'inputProdukImage-dialog',
  templateUrl: 'inputProdukImage-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogProdukImage {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = [];
  ListImage = [];
  imageChangedEvent: any = '';
  gambar: any;
  croppedImage: any = '';
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,

    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
  ngOnInit() {
    if (this.data !== null) {
      this.gambar = this.data.image;
    }
  }
  openfile() {
    jQuery('#imgupload').trigger('click');
  }
  getFileAttachment(event: any) {
    this.imageChangedEvent = event;

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
@Component({
  selector: 'inputProduk-dialog',
  templateUrl: 'inputProduk-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogInputProduk {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  donwloadLink: any = [];
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = {};
  ListImage: any = [];
  ListHarga: any = [];
  mainDb = environment.mainDb;
  constructor(
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<DialogProdukImage>,
    public dialogInput: MatDialogRef<DialogInputProduk>,
    public dialogInputharga: MatDialogRef<DialogDaftarharga>,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    iconRegistry.addSvgIcon(
      'photo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add_photo.svg'));
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
  }
  foods: Food[] = [
    { value: 'Kg', viewValue: 'Kg' },
    { value: 'Gr', viewValue: 'Gram' },
    { value: 'Ikat', viewValue: 'Ikat' },
    { value: 'ml', viewValue: 'Mili Liter' },
    { value: 'L', viewValue: 'Liter' },
    { value: 'Lusin', viewValue: 'Lusin' },
    { value: 'Botol', viewValue: 'Botol' },
    { value: 'Kotak', viewValue: 'Kotak' }
  ];
  ngOnInit() {
    if (this.data.id == null) {
      this.modelProduct.title = this.data.nama;
      this.modelProduct.subtitle = this.data.sub;
      this.modelProduct.ukuran = this.data.ukuran;
      this.modelProduct.satuan = this.data.satuan;
      this.modelProduct.harga = this.data.harga;
      this.modelProduct.type = this.data.type;
      this.modelProduct.editorData = this.data.keterangan;
      this.ListImage.push({ 'image': this.data.image });
    }

  }
  clickNew() {
    this.dialogRef = this.dialog.open(DialogProdukImage, {
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        let gbr = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + result.base64);
        this.ListImage.push({ file: result.file, image: result.base64 });
      }
    });
  }

  addHarga() {
    this.dialogInputharga = this.dialog.open(DialogDaftarharga, {
    });
    this.dialogInputharga.afterClosed().subscribe(result => {
      if (result !== '') {
        this.ListHarga.push({ ukuran: result.ukuran, satuan: result.satuan, nominal: result.nominal });
      }
    });
  }

  RemoveMore(index) {
    this.ListImage.splice(index, 1);
  }

  Removeharga(index) {
    this.ListHarga.splice(index, 1);
  }
  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  editCategory(event) {
    if (this.modelProduct.title == undefined) {
      alert('Nama Produk harus di isi');
      return
    }
    if (this.modelProduct.subtitle == undefined) {
      alert('Intro harus di isi');
      return
    }
    if (this.modelProduct.editorData == undefined) {
      alert('Keterangan harus di isi');
      return
    }
    if (this.modelProduct.kandungan == undefined) {
      alert('Kandungan vitamin harus di isi');
      return
    }

    if (this.ListHarga.length < 1) {
      alert('harga minimal 1');
      return
    }

    if (this.ListImage.length < 1) {
      alert('Image Minimal 1');
      return
    }

    this.loadSpinner();
    var currentUnixTime = Date.now();
    const allPercentage: Observable<number>[] = [];
    for (const { item, index } of this.ListImage.map((item, index) => ({ item, index }))) {
      const filePath = this.data.category + '/' + this.data.id + '_' + currentUnixTime + '_' + index;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, item.file);
      const _percentage$ = task.percentageChanges();
      allPercentage.push(_percentage$);

      // observe percentage changes
      this.uploadPercent = task.percentageChanges();

      // get notified when the download URL is available
      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.donwloadLink.push({ 'url': url });
            if (this.ListImage.length === this.donwloadLink.length) {
              this.db.database.ref(this.mainDb + '/kategori/' + this.data.id + '/produk/' + currentUnixTime).set(
                {
                  'nama': this.modelProduct.title.toString(),
                  'sub': this.modelProduct.subtitle.toString(),
                  'keterangan': this.modelProduct.editorData.toString(),
                  'kandungan': this.modelProduct.kandungan.toString(),
                  'harga': this.ListHarga,
                  'image': this.donwloadLink,
                  'type': this.modelProduct.type == undefined ? 'normal' : this.modelProduct.type,
                }
              ).then(() => {
                this.closeSpinner();
                // if(this.oldGambar !== filePath){
                // this.storage.ref(this.oldGambar).delete(); 
                // }  
                this.dialogInput.close();
              })
            }
          }
          );
        })
      ).subscribe();

    }
  }

}


@Component({
  selector: 'daftarharga-dialog',
  templateUrl: 'daftarharga-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogDaftarharga {

  flag: boolean = true;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  harga: any = [];
  spinnerWithoutBackdrop = false;

  satuan: Satuan[] = [
    { value: 'Kg', viewValue: 'Kg' },
    { value: 'gr', viewValue: 'Gram' },
    { value: 'Ikat', viewValue: 'Ikat' },
    { value: 'ml', viewValue: 'Mili Liter' },
    { value: 'L', viewValue: 'Liter' },
    { value: 'Lusin', viewValue: 'Lusin' },
    { value: 'Botol', viewValue: 'Botol' },
    { value: 'Kotak', viewValue: 'Kotak' }
  ];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

}


@Component({
  selector: 'daftarproduct-dialog',
  templateUrl: 'daftarproduk-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogDaftarProduk {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  modelPengumuman: any = [];
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  selectedRowIndex: string;
  listproduk: ProdukData[] = [];
  dialogRef: any = [];
  dataSource: MatTableDataSource<ProdukData>;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;



  ngOnInit() {
    this.kategori = this.data.category.toUpperCase();
    this.loadSpinner();
    this.db.list('/debug/kategori/' + this.data.id + '/produk')
      .snapshotChanges()
      .subscribe(items => {
        this.listproduk = [];
        items.forEach(item => {
          this.listproduk.push(
            GetListProduk(
              item.key,
              item.payload.val()['nama'],
              item.payload.val()['sub'],
              item.payload.val()['keterangan'],
              item.payload.val()['image'][0]['url'],
              item.payload.val()['image'],
              item.payload.val()['satuan'],
              item.payload.val()['harga'],
              item.payload.val()['filename']
            ));
        });
        this.dataSource = new MatTableDataSource(this.listproduk);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.closeSpinner();
      });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  async getRecord(row) {
    this.dialogRef = this.dialog.open(DialogInputProduk, {
      data: row,
    });
  }
  // getList(item) {
  //   this.dialogRef = this.dialog.open(DialogDaftarProduk, {
  //     height: '95%',
  //     width: '99%',
  //     data: item,
  //   });
  // }

  addProduct(val) {
    this.dialogRef = this.dialog.open(DialogInputProduk, {
      height: '90%',
      width: '50%',
      data: this.data,
    });

  }
}

@Component({
  selector: 'daftarmenu-dialog',
  templateUrl: 'daftarmenu-dialog.html',
  styleUrls: ['daftarmenu.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DialogDaftarmenu {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  modelPengumuman: any = [];
  croppedImage: any = '';

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  //Mat Input Chip SPACE
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  Destination: Destination[] = [
    // { name: 'Guru' },
    // { name: 'Siswa' },
    // { name: 'Guru & Siswa' },
  ];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  mainDb = environment.mainDb;
  temp: any = [];
  constructor(
    public dialogRef: MatDialogRef<DialogDaftarmenu>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  ngOnInit() {
    console.log(this.data);
    if (this.data !== null) { //edit
      this.flag = true;
      this.gambar = this.data.image;
      this.key = this.data.id;
      this.oldGambar = this.data.filename;
      // this.modelPengumuman = this.data.category;
      this.modelPengumuman = this.data;
      // this.Destination.push(this.data.tujuan);
      // console.log(this.Destination);

    } else {
      this.flag = false;
      this.gambar = 'https://firebasestorage.googleapis.com/v0/b/pasarpagisegar.appspot.com/o/empty.png?alt=media&token=3e5b1264-f831-4c28-9d61-2956fbcf1a42';
      this.modelPengumuman = [];
    }

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.Destination.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(Destination: Destination): void {
    const index = this.Destination.indexOf(Destination);

    if (index >= 0) {
      this.Destination.splice(index, 1);
    }
  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  editCategory() {
    if (this.modelPengumuman.judul === '') {
      alert('Judul harus di isi !!!');
      return;
    }


    this.loadSpinner();
    const file = this.croppedImage;
    const filePath = 'pengumuman/' + this.modelPengumuman.judul + '.webp';

    // observe percentage changes
    if (this.flag) {
      if(this.croppedImage !==''){
      const fileRef = this.storage.ref(filePath);
      const task = fileRef.put(this.croppedImage);
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(url => {
          if (url) {
            this.db.database.ref(this.mainDb + '/pengumuman/' + this.key + '/').set(
              {
                'judul': this.modelPengumuman.judul !== undefined ? this.modelPengumuman.judul.toString() : "",
                'filename': filePath.toString(),
                'deskripsi': this.modelPengumuman.deskripsi !== undefined ? this.modelPengumuman.deskripsi.toString() : "",
                'image': url,
                'status': false,
                'link': this.modelPengumuman.url !== undefined ? this.modelPengumuman.url.toString() : "",
  
              }
            ).then(() => {
              this.closeSpinner();
              if (this.oldGambar !== filePath) {
                this.storage.ref(this.oldGambar).delete();
              }
              this.dialogRef.close();
            });
          }
        });
      }else{
        console.log('tesssss')
        this.db.database.ref(this.mainDb + '/pengumuman/' + this.key + '/').set(
          {
            'judul': this.modelPengumuman.judul !== undefined ? this.modelPengumuman.judul.toString() : "",
            'filename': this.data.filename,
            'deskripsi': this.modelPengumuman.deskripsi !== undefined ? this.modelPengumuman.deskripsi.toString() : "",
            'image': this.data.image,
            'status': false,
            'link': this.modelPengumuman.url !== undefined ? this.modelPengumuman.url.toString() : "",

          }
        ).then(() => {
          this.closeSpinner();
          this.dialogRef.close();
        });
      }
      
    } else {
      const fileRefr = this.storage.ref(filePath.toString());
      const task = this.storage.upload(filePath, this.croppedImage);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRefr.getDownloadURL()
          this.downloadURL.subscribe(url => {
            if (url) {
              this.db.database.ref(this.mainDb + '/pengumuman').push(
                {
                  'judul': this.modelPengumuman.judul !== undefined ? this.modelPengumuman.judul.toString() : "",
                  'filename': filePath.toString(),
                  'deskripsi': this.modelPengumuman.deskripsi !== undefined ? this.modelPengumuman.deskripsi.toString() : "",
                  'image': url,
                  'status': false,
                  'link': this.modelPengumuman.url !== undefined ? this.modelPengumuman.url.toString() : "",
                }
              ).then(() => {
                this.closeSpinner();
                this.dialogRef.close();
              })
            }
          })
        })
      )
        .subscribe()
    }
  }

  getFileAttachment(event: any) {
    this.imageChangedEvent = event;
    //   if (event.target.files && event.target.files[0]) {
    //     const file = event.target.files[0];

    //     const reader = new FileReader();
    //     reader.onload = e => this.gambar = reader.result;
    //     reader.readAsDataURL(file);
    // }
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.file;
    this.gambar = event.base64;

  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


}

function GetListMenu(data): MenuData {
  return {
    id: data.key,
    deskripsi: data.val()['deskripsi'],
    image: data.val()['image'],
    judul: data.val()['judul'],
    link: data.val()['link'],
    tujuan: data.val()['tujuan'],
    filename:data.val()['filename'],
  };
}

function GetListTujuan(kategori: string, id: string, subid: string, token: string): iTujuan {
  return {
    kategori: kategori,
    id: id,
    subid: subid,
    token: token,
  };
}

export interface iTujuan {
  kategori: string;
  id: string;
  subid: string;
  token: string;
}

export interface MenuData {
  id: string;
  deskripsi: string;
  image: string;
  judul: string;
  link: string;
  tujuan: string;
  filename:string;
}


function GetListProduk(id: string, nama: string, sub: string, keterangan: string, image: string, listImage: any, satuan: string, harga: number, filename:string): ProdukData {
  return {
    id: id.toString(),
    nama: nama,
    sub: sub,
    keterangan: keterangan,
    image: image,
    listImage: listImage,
    satuan: satuan,
    harga: harga,
    filename:filename,
  };
}

export interface ProdukData {
  id: string;
  nama: string;
  sub: string;
  keterangan: string;
  image: string;
  listImage: any;
  satuan: string;
  harga: number;
  filename:string;
}
export interface Food {
  value: string;
  viewValue: string;
}
export interface Satuan {
  value: string;
  viewValue: string;
}

export interface Destination {
  name: string;
}

export const COMPONENT_LIST = [
  DaftarMenu,
  DialogDaftarmenu,
  DialogDaftarCategory,
  DialogDaftarProduk,
  DialogInputProduk,
  DialogProdukImage,
  DialogDaftarharga,
]
