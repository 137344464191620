import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            // {
            //     id       : 'png',
            //     title    : 'pengumuman',
            //     translate: 'NAV.PENGUMUMAN.TITLE',
            //     type     : 'item',
            //     icon     : 'list',
            //     url      : '/pengumuman',
            //     // badge    : {
            //     //     title    : '25',
            //     //     translate: 'NAV.SAMPLE.BADGE',
            //     //     bg       : '#F44336',
            //     //     fg       : '#FFFFFF'
            //     // }
            // },
            {
                id: 'menu',
                title: 'Pengumuman',
                translate: 'NAV.PENGUMUMAN.TITLE',
                type: 'item',
                icon: 'announcement',
                url: '/listmenu',
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.DETAIL.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id: 'ppdb',
                title: 'PPDB',
                translate: 'NAV.PPDB.TITLE',
                type: 'item',
                icon: 'announcement',
                url: '/ppdb',
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.DETAIL.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id: 'info',
                title: 'Info',
                translate: 'NAV.INFO.TITLE',
                type: 'item',
                icon: 'announcement',
                url: '/info',
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.DETAIL.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            // {
            //     id: 'nilai',
            //     title: 'Daftar nilai',
            //     translate: 'NAV.NILAI.TITLE',
            //     type: 'item',
            //     icon: 'assignment',
            //     url: '/apps/academy',
            //     badge    : {
            //         title    : '1',
            //         translate: 'NAV.SAMPLE.BADGE',
            //         bg       : '#F44336',
            //         fg       : '#FFFFFF'
            //     }

            // },
            // {
            //     id: 'kalendar',
            //     title: 'Kalendar',
            //     translate: 'NAV.KALENDAR.TITLE',
            //     type: 'item',
            //     icon: 'calendar_today',
            //     url: '/apps/calendar',
            //     badge    : {
            //         title    : '1',
            //         translate: 'NAV.SAMPLE.BADGE',
            //         bg       : '#F44336',
            //         fg       : '#FFFFFF'
            //     }
            // },
            {
                id: 'mahasiswa',
                title: 'Daftar Siswa',
                translate: 'NAV.MAHASISWA.TITLE',
                type: 'item',
                icon: 'person',
                url: '/siswa',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }

            },
            {
                id: 'pengaturan',
                title: 'Pengaturan',
                translate: 'NAV.PENGATURAN.TITLE',
                type: 'collapsable',
                icon: 'list',
                children: [
                    {
                        id: 'kelas',
                        title: 'Kelas',
                        translate: 'NAV.KELAS.TITLE',
                        type: 'item',
                        url: '/kelas',
                    },
                    {
                        id: 'jurusan',
                        title: 'Jurusan',
                        translate: 'NAV.JURUSAN.TITLE',
                        type: 'item',
                        url: '/jurusan',
                    },
                    {
                        id: 'matapelajaran',
                        title: 'Mata Pelajaran',
                        translate: 'NAV.MATPEL.TITLE',
                        type: 'item',
                        url: '/matapelajaran',
                    }
                ],
            },
            // {
            //     id: 'kelas',
            //     title: 'Kelas',
            //     translate: 'NAV.KELAS.TITLE',
            //     type: 'item',
            //     icon: 'announcement',
            //     url: '/kelas'
            // },
            
            // {
            //     id: 'jadwalmhs',
            //     title: 'Jadwal PBM',
            //     translate: 'NAV.JADWAL.TITLE',
            //     type: 'item',
            //     icon: 'announcement',
            //     url: '/jadwal',
            //     badge    : {
            //         title    : '1',
            //         translate: 'NAV.SAMPLE.BADGE',
            //         bg       : '#F44336',
            //         fg       : '#FFFFFF'
            //     }

            // },
            {
                id: 'dosen',
                title: 'Daftar Guru',
                translate: 'NAV.DOSEN.TITLE',
                type: 'item',
                icon: 'group',
                url: '/guru',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }

            },
            {
                id: 'profile',
                title: 'Profil Sekolah',
                translate: 'NAV.PROFILE.TITLE',
                type: 'item',
                icon: 'building',
                url: '/profile',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }

            },

            {
                id: 'program',
                title: 'Program Sekolah',
                translate: 'NAV.PROGRAM.TITLE',
                type: 'item',
                icon: 'building',
                url: '/program',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }

            },
            {
                id: 'keluar',
                title: 'Keluar',
                translate: 'NAV.KELUAR.TITLE',
                type: 'item',
                icon: 'settings_power',
                url: '/keluar',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }

            },
        ]
    }

];
