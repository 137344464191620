import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { environment } from 'environments/environment';

declare var jQuery: any;


@Component({
  selector: 'daftardosen',
  templateUrl: './daftardosen.component.html',
  styleUrls: ['./daftardosen.component.scss'],
  animations: fuseAnimations
})
export class DaftarDosen implements OnInit {
  ref: any = [];
  items: any[];
  selectedRowIndex = [];
  displayedColumns = ['id', 'category'];
  loadRef: any = [];
  dialogRef: any = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;
  listmenu: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */



  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadSpinner();
    this.db.list(this.mainDb + '/pengajar')
      .snapshotChanges()
      .subscribe(items => {
        this.listmenu = [];
        items.forEach(item => {
          // console.log(item.key);
          //console.log(item.payload.val());
          // console.log(item.payload.val()['category']);
          this.listmenu.push(GetListMenu(item.payload));
        });
        this.dataSource = new MatTableDataSource(this.listmenu);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.closeSpinner();
      });

  }

  // openDialog(): void {
  //   let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
  //     width: '250px',
  //     data: { name: this.name, animal: this.animal }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.animal = result;
  //   });
  // }
  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.nama + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref(this.mainDb + '/pengajar/' + item.id).remove();
      this.storage.ref(item.filename).delete();
        this.closeSpinner();
    }


  }

  getRecord(row) {

    this.dialogRef = this.dialog.open(DialogDaftardosen, {
      data: row,
    });
  }
  // getList(item){
  //   this.dialogRef = this.dialog.open(DialogDaftarProduk, {
  //     height: '95%',
  //     width:'99%',
  //     data:item,
  //   });
  // }

  addProduct() {
    this.dialogRef = this.dialog.open(DialogDaftardosen, {
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
@Component({
  selector: 'daftardosen-dialog',
  templateUrl: 'daftardosen-dialog.html',
  styleUrls: ['daftardosen.component.scss']
})
export class DialogDaftarCategory {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = [];
  modelDosen: any = [];
  ListImage = [];
  imageChangedEvent: any = '';
  gambar: any;
  croppedImage: any = '';
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,

    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
  ngOnInit() {
    if (this.data !== null) {
      this.gambar = this.data.image;
    }
  }
  openfile() {
    jQuery('#imgupload').trigger('click');
  }
  getFileAttachment(event: any) {
    this.imageChangedEvent = event;

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}

@Component({
  selector: 'daftardosen-dialog',
  templateUrl: 'daftardosen-dialog.html',
  styleUrls: ['daftardosen.component.scss']
})
export class DialogDaftardosen {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  modelDosen: any = [];
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;
  constructor(
    public dialogRef: MatDialogRef<DialogDaftardosen>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  ngOnInit() {
    console.log(this.data);
    if (this.data !== null) {
      this.flag = true;
      this.gambar = this.data.image;
      this.key = this.data.id;
      this.oldGambar = this.data.filename;
      this.modelDosen = this.data;

    } else {
      this.flag = false;
      this.gambar = 'https://firebasestorage.googleapis.com/v0/b/pasarpagisegar.appspot.com/o/empty.png?alt=media&token=3e5b1264-f831-4c28-9d61-2956fbcf1a42';
      this.kategori = '';
    }

  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  editCategory() {
    if (this.modelDosen.nama === '') {
      alert('Nama belum di isi !!!');
      return;
    }
    this.loadSpinner();
    const file = this.croppedImage;
    const filePath = 'pengajar/' + this.modelDosen.nama + '.webp';

    // observe percentage changes
    if (this.flag) {
      if(this.croppedImage!==''){
        const fileRef = this.storage.ref(filePath);
        const task = fileRef.put(this.croppedImage);
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(url => {
          if (url) {
            this.db.database.ref(this.mainDb + '/pengajar/' + this.key + '/').set(
              {
                'nama': this.modelDosen.nama.toString(),
                'nip': this.modelDosen.nip !== undefined ? this.modelDosen.nip.toString() : "",
                'jabatan': this.modelDosen.jabatan !== undefined ? this.modelDosen.jabatan.toString() : "",
                'filename': filePath.toString(),
                'image': url,
              }
            ).then(() => {
              this.closeSpinner();
              if (this.oldGambar !== filePath) {
                this.storage.ref(this.oldGambar).delete();
              }
              this.dialogRef.close();
            });
          }
        });
      }else{
        this.db.database.ref(this.mainDb + '/pengajar/' + this.key + '/').set(
          {
            'nama': this.modelDosen.nama.toString(),
            'nip': this.modelDosen.nip !== undefined ? this.modelDosen.nip.toString() : "",
            'jabatan': this.modelDosen.jabatan !== undefined ? this.modelDosen.jabatan.toString() : "",
            'filename':this.data.filename,
            'image': this.data.image,
          }
        ).then(() => {
          this.closeSpinner();
          if (this.oldGambar !== filePath) {
            this.storage.ref(this.oldGambar).delete();
          }
          this.dialogRef.close();
        });
      }
     
    } else {
      // [nama: "Ria", nip: "-", jabatan: "Bahasa Inggris"]
      const fileRefr = this.storage.ref(filePath.toString());
      const task = this.storage.upload(filePath, this.croppedImage);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRefr.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.db.database.ref(this.mainDb + '/pengajar/').push(
                {
                  'nama': this.modelDosen.nama.toString(),
                  'nip': this.modelDosen.nip !== undefined ? this.modelDosen.nip.toString() : "",
                  'filename': filePath.toString(),
                  'jabatan': this.modelDosen.jabatan !== undefined ? this.modelDosen.jabatan.toString() : "",
                  'image': url,
                }
              ).then(() => {
                this.closeSpinner();
                this.dialogRef.close();
              })
            }
          })
        })
      )
        .subscribe();
    }
  }

  getFileAttachment(event: any) {
    this.imageChangedEvent = event;
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.file;
    this.gambar = event.base64;

  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


}

function GetListMenu(data): MenuData {

  return {
    id: data.key,
    jabatan: data.val()['jabatan'],
    image: data.val()['image'],
    nama: data.val()['nama'],
    nip: data.val()['nip'],
    filename:data.val()['filename'],
  };
}

export interface MenuData {
  id: string;
  jabatan: string;
  image: string;
  nama: string;
  nip: string;
  filename: string;
}



export const COMPONENT_LIST = [
  DaftarDosen,
  DialogDaftardosen,
]
