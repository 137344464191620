import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MatInputModule } from '@angular/material/input';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
declare var jQuery: any;
import { environment } from 'environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ProgramComponent implements OnInit {
  ref: any = [];
  items: any[];
  selectedRowIndex = [];
  displayedColumns = ['id', 'category'];
  loadRef: any = [];
  dialogRef: any = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;
  listmenu: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadSpinner();
    this.db.list(this.mainDb + '/program')
      .snapshotChanges()
      .subscribe(items => {
        this.listmenu = [];
        items.forEach(item => {
          this.listmenu.push(GetListMenu(item.payload));
          console.log(this.listmenu);
        });
        this.dataSource = new MatTableDataSource(this.listmenu);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.closeSpinner();
      });
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  getRecord(item) {
    this.dialogRef = this.dialog.open(DialogProgram, {
      panelClass: 'dialog',
      width: '700px',
      hasBackdrop: true,
      data: item,
    });
  }

  delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.judul + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref(this.mainDb +'/program/' + item.id).remove();
      this.storage.ref(item.filename).delete().subscribe(() => {
        this.closeSpinner();
      })
    }
  }

  // getRecord(row) {
  //   this.dialogRef = this.dialog.open(DialogProfile, {
  //     data: row,
  //   });
  // }

  addProduct() {
    this.dialogRef = this.dialog.open(DialogProgram, {
      panelClass: 'dialog',
      width: '700px',
      hasBackdrop: true,
      data: {
        action: 'new',
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
@Component({
  selector: 'profile-dialog',
  templateUrl: 'tambahprogram/tambahprogram.component.html',
  styleUrls: ['tambahprogram/tambahprogram.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class DialogProgram {
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  ModelProgram: any = [];
  currentUnixTime = Date.now();
  mainDb = environment.mainDb;
  constructor(
    public dialogRef: MatDialogRef<DialogProgram>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  ngOnInit() {
    console.log(this.data);
    this.ModelProgram = this.data;
  }


  openfile() {
    jQuery('#imgupload').trigger('click');
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  simpan() {
    this.loadSpinner();
    this.db.database.ref(this.mainDb +'/program/' + this.data.id).set({
      'judul': this.ModelProgram.judul,
      'isi': this.ModelProgram.isi,
      'image': this.data.image
    }).then(() => {
      this.closeSpinner();
      this.dialogRef.close();
    });
  }
}
function GetListMenu(data): MenuData {
  return {
    id: data.key,
    image: data.val()['image'],
    isi: data.val()['isi'],
    judul: data.val()['judul'],
  };
}

export interface MenuData {
  id: string;
  image: string;
  isi: string;
  judul: string;
}

export const COMPONENT_LIST = [
  ProgramComponent,
  DialogProgram
]

