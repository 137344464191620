// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { debug } from "console";

export const environment = {
  production: false,
  hmr: false,
  // firebaseConfig : {
  //   apiKey: "AIzaSyDafLN6rFzR3zs61tzaz-a8nShCUy5xRMs",
  //   authDomain: "infokampus-6ded6.firebaseapp.com",
  //   databaseURL: "https://infokampus-6ded6.firebaseio.com",
  //   projectId: "infokampus-6ded6",
  //   storageBucket: "infokampus-6ded6.appspot.com",
  //   messagingSenderId: "302301056354",
  //   appId: "1:302301056354:web:3a228c16348e3c85dbb03b"
  // },
  mainDb:'debug',
  BaseUrl: 'https://fcm.googleapis.com/fcm/send',
  firebaseConfig: {
  apiKey: "AIzaSyAno46zwB-UVsVKz6KGnLt5-as7kE4oChs",
  authDomain: "esekolah-6c5a4.firebaseapp.com",
  databaseURL: "https://esekolah-6c5a4.firebaseio.com",
  projectId: "esekolah-6c5a4",
  storageBucket: "esekolah-6c5a4.appspot.com",
  messagingSenderId: "541920065221",
  appId: "1:541920065221:web:90d8d511057e5cc4a4e2ac",
  measurementId: "G-LTR04N30YR"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
