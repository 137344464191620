import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { FormControl, Validators, Form, NgForm, FormGroup } from '@angular/forms';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { animate } from '@angular/animations';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-jurusan',
  templateUrl: './jurusan.component.html',
  styleUrls: ['./jurusan.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class JurusanComponent implements OnInit {
  dialogRef: any = [];
  dataSource: MatTableDataSource<iJurusan>;
  obs: Observable<any>;

  listJurusan: iJurusan[] = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.LoadJurusan();
  }

  LoadJurusan() {
    this.db.list(this.mainDb + '/jurusan/').snapshotChanges().subscribe(items => {
      this.listJurusan = [];
      items.forEach(item => {
        this.listJurusan.push(getJurusan(item.payload));
      });
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listJurusan);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.obs = this.dataSource.connect();
      }, 500);
    });
  }

  tambahjurusan(): void {
    this.dialogRef = this.dialog.open(Dialogtambahjurusan, {
      panelClass: 'dialog',
      width: '30%',
      hasBackdrop: true,
      data: {
        action: 'new',
      }
    });
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  ubah(item) {
    this.dialogRef = this.dialog.open(Dialogtambahjurusan, {
      panelClass: 'dialog',
      width: '30%',
      hasBackdrop: true,
      data: {
        action: 'edit',
        jurusan: item,
      }
    });
  }

  hapus(item) {
    console.log(item);
    var r = confirm("Yakin ingin menghapus ?");
    if (r == true) {
      console.log(item);
      this.loadSpinner();
      this.db.database.ref(this.mainDb + '/jurusan/' + item.id).remove().then(() => {
        this.db.database.ref(this.mainDb + '/detailJurusan/' + item.id).remove().then(() => {
          this.closeSpinner();
        })
      });
    }
  }
}

@Component({
  selector: 'tambahjurusan',
  templateUrl: 'tambahjurusan/tambahjurusan.component.html',
  styleUrls: ['tambahjurusan/tambahjurusan.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class Dialogtambahjurusan {
  jurusanForm: FormGroup;
  jurusanModel: any = [];
  title: string;
  subjurusan: any = [];
  DataSub: any = [];

  currentUnixTime = Date.now();
  i: number = 0;

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;

  constructor(
    public dialogRef: MatDialogRef<Dialogtambahjurusan>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase, public _MatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  ngOnInit(): void {
    this.jurusanForm = new FormGroup({
      nama: new FormControl(''),
      deskripsi: new FormControl(''),
    });

    if (this.data.action === 'new') {
      this.title = 'Jurusan Baru';
    } else {
      console.log(this.data);
      this.title = 'Edit Jurusan';
      this.jurusanModel = this.data.jurusan;
      this.db.list(this.mainDb + '/detailJurusan/').snapshotChanges().subscribe(result => {
        result.forEach(res => {
          let jur = res.key;
          this.db.list(this.mainDb + '/detailJurusan/' + jur + '/').snapshotChanges().subscribe(items => {
            this.DataSub = [];
            items.forEach(item => {
              this.DataSub.push({
                'id': jur,
                'subjur': item.key,
                'sub': item.payload.val()['sub'],
              });
              console.log(this.DataSub);
            });
          })
        })
      })
    }
  }

  Sub() {
    this.subjurusan = this._MatDialog.open(SubDialog, {
      panelClass: 'dialog',
      hasBackdrop: true,
    });

    this.subjurusan.afterClosed().subscribe(result => {
      console.log(result);
      if (result !== '') {
        this.DataSub.push({
          'sub': result
        });
        console.log(this.jurusanModel);
        // this.syarat_bayar.push({ syarat_bayar: result.syarat_bayar, keterangan: result.keterangan });
        // console.log(this.syarat_bayar);
      }
    });
  }


  removeItem(i: number): void {
    if (this.data.action === 'new') {
      this.DataSub.splice(i, 1);
    } else {
      this.db.database.ref(this.mainDb + '/detailJurusan/' + this.DataSub.id + '/' + this.DataSub.subjur).remove();
    }
  }

  simpan() {
    // console.log(this.jurusanModel);
    // console.log(this.DataSub);
    this.loadSpinner();
    this.db.database.ref(this.mainDb + '/jurusan/' + this.currentUnixTime).set({
      'nama': this.jurusanModel.nama,
      'deskripsi': this.jurusanModel.deskripsi
    }).then(() => {
      this.closeSpinner();
      this.dialogRef.close();
    });

    this.DataSub.forEach(item => {
      this.db.database.ref(this.mainDb + '/detailJurusan/' + this.currentUnixTime + '/' + this.currentUnixTime + this.i).set({
        'sub': item.sub
      });
      this.i++;
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }
}

@Component({
  selector: 'tambahjurusan',
  templateUrl: 'tambahjurusan/sub/subjurusan.component.html',
  styleUrls: ['tambahjurusan/tambahjurusan.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class SubDialog {
  subForm: FormGroup;
  sub: any = [];

  constructor(
    public dialogRef: MatDialogRef<SubDialog>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase, public _MatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {
    this.subForm = new FormGroup({
      sub: new FormControl(''),
    });
  }
}

function getJurusan(data): iJurusan {
  return {
    id: data.key,
    nama: data.val()['nama'],
    deskripsi: data.val()['deskripsi'],

  };
}
export interface iJurusan {
  id: string;
  nama: string;
  deskripsi: string;
}

export const COMPONENT_LIST = [
  JurusanComponent,
  Dialogtambahjurusan,
  SubDialog
];

