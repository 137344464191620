import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import {
    MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
    MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
    MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule,
    MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
    MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
    MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { DaftarDosen } from './daftardosen.component';
import { COMPONENT_LIST } from './daftardosen.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppOverlayModule } from '../../overlay/overlay.module';
import { ProgressSpinnerModule, ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';

import * as jquery from 'jquery';
window['jQuery'] = jquery;
window['$'] = jquery;

const routes = [
    {
        path: 'guru',
        component: DaftarDosen
    }
];

@NgModule({
    declarations: [
        DaftarDosen,
        [...COMPONENT_LIST],
    ],
    providers: [

    ],
    entryComponents: COMPONENT_LIST,
    imports: [
        RouterModule.forChild(routes),
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TranslateModule,
        AppOverlayModule,
        ProgressSpinnerModule,
        BrowserModule,
        FuseSharedModule,
        ImageCropperModule
    ],
    exports: [
        DaftarDosen,
    ]
})

export class DaftarDosenModule {
}
