import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable, of } from 'rxjs';
import { finalize, subscribeOn, delay } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import * as XLSX from 'xlsx';
declare var jQuery: any;
import * as $ from 'jquery';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiService } from 'app/services/api.service';
import { environment } from 'environments/environment';
import { isThursday } from 'date-fns';

@Component({
  selector: 'app-mahasiswa',
  templateUrl: './mahasiswa.component.html',
  styleUrls: ['./mahasiswa.component.scss']
})
export class MahasiswaComponent implements OnInit {
  listmenu: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;

  listNilai: any = [];
  listJurusan: iJurusan[] = [];
  DetailJur: iDetailJur[] = [];
  listTingkat: iTingkat[] = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  ExcelData: MenuData[] = [];
  finalExcel: any[] = [];

  DataNilai: iDataNilai[] = [];
  dialogRef: any = [];
  mainDb = environment.mainDb;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadPengguna();
    // this.getNilai();
    this.getJurusanKelas();
  }

  openfile() {
    $('#fileSrc').trigger('click');
  }

  excelFile(args: any) {
    var files = args.target.files; // FileList object
    this.parseExcel(files[0]);
  }

  parseExcel(file) {
    var reader = new FileReader();
    // var i:number = 0;
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });
      workbook.SheetNames.forEach((async function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        // console.log(XL_row_object);
        this.ExcelData.push(XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]));
        this.StoreDB(await this.ExcelData);
        //this.gridObj.dataSource = JSON.parse(json_object);
      }).bind(this), this);
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  async StoreDB(data) {
    let i = 0;
    data[0].forEach(items => {
      console.log(items);
      this.db.database.ref(this.mainDb + '/siswa/').push(
        {
          // 'nis':items.NIS,
          'nama': items.NAMA !== undefined ? items.NAMA : "",
          'gender': items.JENISKELAMIN !== undefined ? items.JENISKELAMIN : "",
          'tingkat': items.KELAS !== undefined ? items.KELAS : "",
          'jurusan': items.JURUSAN !== undefined ? items.JURUSAN : "",
          'detailJurusan': items.DETAILKELAS !== undefined ? items.DETAILKELAS : "",
          'tempatlahir': items.TEMPATLAHIR !== undefined ? items.TEMPATLAHIR : "",
          'tgllahir': items.TANGGALLAHIR !== undefined ? this.ExcelDateToJSDate(items.TANGGALLAHIR).valueOf() : "",
          'alamat': items.ALAMAT !== undefined ? items.NAMA : "",
          'profile': "",
          'jadwal': "",
          'bio': items.BIO !== undefined ? items.BIO : "",
        }
      ).then((res) => {
        console.log(res);
      });
    });
    i++;
  }

  ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
    var total_seconds = Math.floor(86400 * fractional_day);
    var seconds = total_seconds % 60;
    total_seconds -= seconds;
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }

  getJurusanKelas() {
    this.db.list(this.mainDb + '/detailJurusan/').snapshotChanges().subscribe(kelas => {
      this.DetailJur = [];
      kelas.forEach(data => {
        var idJur = data.key;
        this.db.list(this.mainDb + '/detailJurusan/' + idJur + '/').snapshotChanges().subscribe(items => {
          items.forEach(item => {
            this.DetailJur.push(getDetailJur(idJur, item.key, item.payload.val()['sub']));
            // console.log(this.listKelas);
          });
        });
      });
    });

    this.db.list(this.mainDb + '/tingkatan/').snapshotChanges().subscribe(items => {
      this.listTingkat = [];
      items.forEach(item => {
        this.listTingkat.push(getlistTingkat(item.payload));
      })
    });

    this.db.list(this.mainDb + '/jurusan/').snapshotChanges().subscribe(items => {
      this.listJurusan = [];
      items.forEach(item => {
        this.listJurusan.push(getJurusan(item.key, item.payload.val()['nama']));
        // console.log(this.listJurusan);
      });
    });
  }

  getNilai() {
    this.db.list(this.mainDb + '/nilai/').snapshotChanges().subscribe(result => {
      this.listNilai = [];
      result.forEach(items => {
        let Mhskey = items.key;
        this.listNilai.push(getNilaiMhs(Mhskey, items.payload.val()['uas'], items.payload.val()['uts']));
        // console.log(this.listNilai);
      });
    });
  }

  loadPengguna() {
    this.db.list(this.mainDb + '/siswa/').snapshotChanges().subscribe(
      datas => {
        this.listmenu = [];
        datas.forEach(data => {
          this.listmenu.push(getListMhs(
            data.key,
            data.payload.val()['jadwal'],
            data.payload.val()['nama'],
            data.payload.val()['nilai'],
            data.payload.val()['tingkat'],
            data.payload.val()['jurusan'],
            data.payload.val()['detailJurusan'],
            data.payload.val()['profile'],
            data.payload.val()['alamat'],
            data.payload.val()['bio'],
            data.payload.val()['gender'],
            data.payload.val()['tempatlahir'],
            data.payload.val()['absensi'],
            data.payload.val()['spp'],
            data.payload.val()['token'],
            new Date(Number(data.payload.val()['tgllahir'])),
            data.payload.val()['tahun'],
            data.payload.val()['kepala'],
            data.payload.val()['priode'],
          ));
        });
        this.dataSource = new MatTableDataSource(this.listmenu);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  getDetail(el) {
    const dialog = this.dialog.open(detailMhs, {
      hasBackdrop: true,
      panelClass: 'dialog',
      width: '80%',
      height: '100%',
      data: el,
    });
  }
  // addRecord() {
  //   const dialog = this.dialog.open(addMhs, {
  //     hasBackdrop: true,
  //     panelClass: 'dialog',
  //     width: '30%',
  //     data: {
  //       action: 'new',
  //       detailjur: this.DetailJur,
  //       jurusan: this.listJurusan,
  //       tingkat: this.listTingkat,
  //     }
  //   });
  // }

  addRecord() {
    this.dialogRef = this.dialog.open(DialogMahasiswa, {
      panelClass: 'dialog',
      width: '50%',
      height: '90%',
      hasBackdrop: true,
      data: {
        action: 'new',
        detailjur: this.DetailJur,
        jurusan: this.listJurusan,
        tingkat: this.listTingkat,
      }
    });
  }

  getRecord(item) {
    const dialog = this.dialog.open(DialogMahasiswa, {
      panelClass: 'dialog',
      width: '50%',
      height: '90%',
      hasBackdrop: true,
      data: {
        action: 'edit',
        detailjur: this.DetailJur,
        jurusan: this.listJurusan,
        tingkat: this.listTingkat,
        UserData: item
      }
    });
  }

  inputAbsensi(item) {
    const dialog = this.dialog.open(inputAbsensi, {
      panelClass: 'dialog',
      width: '40%',
      hasBackdrop: true,
      data: {
        UserData: item,
      },
    });

    dialog.afterClosed().subscribe(result => {
      //console.log(result);

    });
  }

  inputSPP(item) {
    const dialog = this.dialog.open(inputSPP, {
      panelClass: 'dialog',
      width: '50%',
      hasBackdrop: true,
      data: {
        UserData: item,
      },
    });
    dialog.afterClosed().subscribe(result => {
      // console.log(result);

    });
  }

  delRecord() { }
}
@Component({
  selector: 'mahasiswa-dialog',
  templateUrl: 'mahasiswa-dialog.html',
  styleUrls: ['mahasiswa.component.scss'],
  animations: fuseAnimations,
})
export class DialogMahasiswa {
  modelMhs: any = [];
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  nama: any;
  alamat: any;
  jumlah: any;
  oldGambar: any;
  flag: boolean;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  currentUnixTime = Date.now();
  title: string = '';

  tmpDetailJur: any = [];
  listJurusan: iJurusan[] = [];
  detailjur: iDetailJur[] = [];
  listTingkat: iTingkat[] = [];
  mainDb = environment.mainDb;
  btnAction: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogMahasiswa>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  ngOnInit(): void {
    this.listJurusan = this.data.jurusan;
    this.detailjur = this.data.detailjur;
    this.listTingkat = this.data.tingkat;
    if (this.data.action === 'new') {
      this.flag = true;
      this.title = 'Siswa Baru';
      this.btnAction = 'BATALKAN';
    }
    else {
      this.tmpDetailJur = [];
      this.flag = false;
      this.oldGambar = this.data.UserData.profile;
      this.gambar = this.data.UserData.profile;
      this.modelMhs.tahun = this.data.UserData.tahun === 'undefined' ? '' : this.data.UserData.tahun;
      this.modelMhs.priode = this.data.UserData.priode === 'undefined' ? '' : this.data.UserData.priode;
      this.modelMhs = this.data.UserData;
      this.tmpDetailJur = this.data.detailjur.filter((item) => {
        return item.idJur === this.data.UserData.jurusan;
      });
      this.title = 'Edit Siswa';
      this.btnAction = 'HAPUS';
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  JurusanChange(e) {
    this.tmpDetailJur = [];
    let tmp = e.value;
    this.tmpDetailJur = this.detailjur.filter((item) => {
      return item.idJur === tmp;
    });
  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }

  getFileAttachment(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.file;
    this.gambar = event.base64;
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  simpan() {
    this.loadSpinner();
    const filePath = 'siswa/' + this.modelMhs.nama + '.webp';
    if (this.croppedImage !== '') {
      const file = this.croppedImage;
      const fileRefr = this.storage.ref(filePath.toString());
      const task = this.storage.upload(filePath, this.croppedImage);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRefr.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.db.database.ref(this.mainDb + '/siswa/' + this.data.UserData.id).update(
                {
                  'nama': this.modelMhs.nama != null ? this.modelMhs.nama : '',
                  'gender': this.modelMhs.gender != null ? this.modelMhs.gender : '',
                  'kelas': this.modelMhs.tingkat != null ? this.modelMhs.tingkat : '',
                  'priode': this.modelMhs.priode != null ? this.modelMhs.priode : '',
                  'tahun': this.modelMhs.tahun != null ? this.modelMhs.tahun : '',
                  'kepala': this.modelMhs.kepala != null ? this.modelMhs.kepala : '',
                  'jurusan': '',
                  'detailJurusan': '',
                  'tempatlahir': this.modelMhs.tempatlahir != null ? this.modelMhs.tempatlahir : '',
                  'tgllahir': this.modelMhs.tgllahir.valueOf() != null ? this.modelMhs.tgllahir.valueOf() : '',
                  'alamat': this.modelMhs.alamat != null ? this.modelMhs.alamat : '',
                  'profile': url,
                  'jadwal': "",
                  // 'bio': this.modelMhs.bio !== '' ? this.modelMhs.bio : '',
                }
              ).then(() => {
                this.modelMhs = {};
                this.spinnerWithoutBackdrop = false;
                this.dialogRef.close();
              });
            }
          });
        })
      )
        .subscribe(result => { });
    } else {
      this.db.database.ref(this.mainDb + '/siswa/' + this.data.UserData.id).update(
        {
          'nama': this.modelMhs.nama != null ? this.modelMhs.nama : '',
          'gender': this.modelMhs.gender != null ? this.modelMhs.gender : '',
          'kelas': this.modelMhs.tingkat != null ? this.modelMhs.tingkat : '',
          'priode': this.modelMhs.priode != null ? this.modelMhs.priode : '',
          'tahun': this.modelMhs.tahun != null ? this.modelMhs.tahun : '',
          'kepala': this.modelMhs.kepala != null ? this.modelMhs.kepala : '',
          'jurusan': '',
          'detailJurusan': '',
          'tempatlahir': this.modelMhs.tempatlahir != null ? this.modelMhs.tempatlahir : '',
          'tgllahir': this.modelMhs.tgllahir.valueOf() != null ? this.modelMhs.tgllahir.valueOf() : '',
          'alamat': this.modelMhs.alamat != null ? this.modelMhs.alamat : '',
          'profile': this.data.UserData.profile,
          'jadwal': "",
          // 'bio': this.modelMhs.bio !== '' ? this.modelMhs.bio : '',
        }
      ).then(() => {
        this.modelMhs = {};
        this.spinnerWithoutBackdrop = false;
        this.dialogRef.close();
      });
    }

  }
}

@Component({
  selector: 'absensi',
  templateUrl: 'absensi/absensi.html',
  styleUrls: ['absensi/absensi.scss'],
  // encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class inputAbsensi {
  ModelAbsensi: any = [];
  bulantahun: any;
  tglHari: any;
  mainDb = environment.mainDb;

  constructor(
    public dialogRef: MatDialogRef<inputAbsensi>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.bulantahun = moment(new Date()).format("MM-YYYY");
    this.tglHari = moment(new Date()).format("D");
    console.log(this.bulantahun);
  }

  simpan() {
    this.db.database.ref(this.mainDb + '/siswa/' + this.data.UserData.id + '/absensi/' + this.bulantahun + '/' + this.tglHari).set({
      'keterangan': this.ModelAbsensi.keterangan !== undefined ? this.ModelAbsensi.keterangan : '',
    }).then((res) => {
      this.dialogRef.close();
    });
  }

}

@Component({
  selector: 'detailmhs-dialog',
  templateUrl: 'detailMhs/detailmhs.html',
  styleUrls: ['detailMhs/detailmhs.scss'],
  // encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class detailMhs {

  displayedColumns = ['jam', 'matpel', 'guru'];
  displayedColumns2 = ['matpel', 'npraktek', 'nakhir', 'nharian', 'nmid', 'nraport'];


  dataSource: MatTableDataSource<any>;
  dataSource2: MatTableDataSource<any>;

  listMhs: any = [];
  listMatkul: any[] = [];

  mshForm: FormGroup;

  nilai: any = [];
  jadwal: any = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  DataNilai: iDataNilai[] = [];
  FinalDataNilai: iDataNilai[] = [];
  currentUnixTime = Date.now();
  isLoading = true;
  msgCOnfirm = {
    "notification": {
      "image": "https://firebasestorage.googleapis.com/v0/b/esekolah-6c5a4.appspot.com/o/profile%2Fskul.jpg?alt=media&token=4e5bbef1-b450-4de3-a16c-c1c05ea85b04",
      "color": "#990000",
      "click_action": "FLUTTER_NOTIFICATION_CLICK",
      "title": "Lakukan Pembayaran SPP",
      "body": "Pembayaran SPP Terakhir Tanggal 15"
    },
    "data": {
      "keyname": "any value"
    },
    // "to": "dn87btsM7Bg:APA91bHlYCHdJJNXVJcoWsq_jUtcumE8KJ3M2ir-UQweGSbeXouEFhHQMjfjn4SLgkiOY06Bar9Q0a4IWiZGUClZHJaK6nF7787eGJRjay6f3FNUIvsD7RdiIDXM_RibXb1rb8YTuQaG"
  };

  listJadwal: iJadwal[] = [];
  finaljadwal: iJadwal[] = [];
  tmphari: string = '';
  AngkaHari: string = '';
  Listdate: any = [];
  SelectDate: any = [];
  mainDb = environment.mainDb;
  /**
 * Constructor
 *
 * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
 */

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  constructor(
    http: HttpClient,
    private API: ApiService,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public Inilai: MatDialogRef<inputNilai>,
    public dialogRef: MatDialogRef<detailMhs>,

    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // this.loadSpinner();
    // console.log(this.data);
    this.Listdate = moment.weekdays();
    this.listMhs = this.data;
    this.jadwal = this.data.jadwal[1];
    this.dataSource = new MatTableDataSource(this.jadwal);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.listMatkul = [];
    this.db.list(this.mainDb + '/masterPelajaran/').snapshotChanges().subscribe(indx => {
      indx.forEach(id => {
        this.listMatkul.push(getMataKuliah(id.key, id.payload.val()['nama'], id.payload.val()['kodePelajaran']));
        // console.log(this.listMatkul);
      });
    });

    this.mshForm = new FormGroup({
      nama: new FormControl('', [Validators.required]),
      kelas: new FormControl('', [Validators.required]),
    });

    this.loadDataNilai();
    this.AngkaHari = moment().format('d').toString();
    this.loaddata();
  }

  async onPilChange(e) {
    this.SelectDate = [];
    this.tmphari = '';
    // console.log(e.value);
    this.tmphari = moment().day(e.value.toString()).format('d');
    console.log(this.tmphari);
    // hari = 0;
    //  console.log(this.listJadwal);
    this.SelectDate = this.listJadwal.filter(item => {
      return item.idHari === this.tmphari && item.idKelas === this.data.tingkat && item.idJurusan === this.data.jurusan && item.idDetailJurusan === this.data.detailJurusan;
    });

    of(this.SelectDate).pipe(delay(800))
      .subscribe(data => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, error => this.isLoading = false);
  }

  loaddata() {
    this.listJadwal = [];
    this.db.list(this.mainDb + '/jadwal/').snapshotChanges().subscribe(kelass => {
      this.listJadwal = [];
      kelass.forEach(kelas => {
        let idKelas = kelas.key;
        this.db.list(this.mainDb + '/jadwal/' + idKelas + '/').snapshotChanges().subscribe(jurusans => {
          jurusans.forEach(jurusan => {
            let idJurusan = jurusan.key;
            this.db.list(this.mainDb + '/jadwal/' + idKelas + '/' + idJurusan + '/').snapshotChanges().subscribe(detailJurusans => {
              detailJurusans.forEach(detailJurusan => {
                let iddetailJurusan = detailJurusan.key;
                this.db.list(this.mainDb + '/jadwal/' + idKelas + '/' + idJurusan + '/' + iddetailJurusan + '/').snapshotChanges().subscribe(haris => {
                  haris.forEach(hari => {
                    let idHari = hari.key;
                    this.db.list(this.mainDb + '/jadwal/' + idKelas + '/' + idJurusan + '/'
                      + iddetailJurusan + '/' + idHari + '/').snapshotChanges().subscribe(jadwals => {
                        jadwals.forEach(jadwal => {
                          let idJadwal = jadwal.key;
                          // console.log(jadwal.payload.val());
                          this.listJadwal.push(getJadwal(idKelas, idJurusan, iddetailJurusan,
                            idHari, idJadwal, jadwal.payload.val()['guru'], jadwal.payload.val()['jam'], jadwal.payload.val()['matapelajaran']));
                          // console.log(this.listJadwal);
                        });
                        setTimeout(() => {
                          this.finaljadwal = this.listJadwal.filter(item => {
                            return item.idHari === this.AngkaHari && item.idKelas === this.data.tingkat && item.idJurusan === this.data.jurusan && item.idDetailJurusan === this.data.detailJurusan;
                          })
                          of(this.finaljadwal).pipe(delay(800))
                            .subscribe(data => {
                              this.isLoading = false;
                              this.dataSource = new MatTableDataSource(data);
                              this.dataSource.paginator = this.paginator;
                              this.dataSource.sort = this.sort;
                            }, error => this.isLoading = false);
                          // this.dataSource = new MatTableDataSource(this.finaljadwal);
                          // this.dataSource.paginator = this.paginator;
                          // this.dataSource.sort = this.sort;
                        }, 500);
                      })
                  })
                });
              })
            })
          })
        })
      });
    })
  }

  loadDataNilai() {
    this.db.list(this.mainDb + '/siswa/').snapshotChanges().subscribe(idsiswa => {
      idsiswa.forEach(ids => {
        let idkey = ids.key;
        this.db.list(this.mainDb + '/siswa/' + idkey + '/nilai/').snapshotChanges().subscribe(idnilais => {
          idnilais.forEach(idn => {
            let idnkey = idn.key;
            this.db.list(this.mainDb + '/siswa/' + idkey + '/nilai/' + idnkey).snapshotChanges().subscribe(items => {
              items.forEach(item => {
                this.DataNilai.push(GetDataNilai(idkey, idnkey, item.payload.val()['MataPelajaran'],
                  item.payload.val()['NilaiHarian'], item.payload.val()['NilaiMid'], item.payload.val()['NilaiAkhir'],
                  item.payload.val()['NilaiAPraktek'], item.payload.val()['NilaiRaport']))
                // console.log(this.DataNilai);
                // console.log(item.key);
              });
              setTimeout(() => {
                this.FinalDataNilai = this.DataNilai.filter(x => {
                  return x.idSiswa === this.data.id;
                });
                // console.log(this.FinalDataNilai);
                of(this.FinalDataNilai).pipe(delay(800))
                  .subscribe(data => {
                    this.isLoading = false;
                    this.dataSource2 = new MatTableDataSource(data);
                    this.dataSource2.paginator = this.paginator;
                    this.dataSource2.sort = this.sort;
                  }, error => this.isLoading = false);

              }, 500);
            });
          })
        })
      });
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  inputNilai() {
    this.Inilai = this.dialog.open(inputNilai, {
      hasBackdrop: true,
      panelClass: 'dialog',
      data: { matkul: this.listMatkul, mhs: this.listMhs },
      width: '60%',
    });
    this.Inilai.afterClosed().subscribe(result => {
      console.log(result);

    });
  }

  alertSPP() {
    // console.log(this.data.token[0].token);
    this.msgCOnfirm["to"] = this.data.token[0].token
    this.msgCOnfirm.notification["priority"] = "high";
    var answer = window.confirm("Apakah anda yakin ingin mengirim notifikasi ?")
    if (answer) {
      this.API.confirmToken(this.msgCOnfirm).subscribe(result => {
        console.log(result);
      });

      this.db.database.ref(this.mainDb + '/siswa/' + this.data.id + '/notifikasi/' + this.currentUnixTime).set({
        "title": "Lakukan Pembayaran SPP",
        "body": "Pembayaran SPP Terakhir Tanggal 15"
      });
    }
  }
}
@Component({
  selector: 'inputNilai',
  templateUrl: 'inputNilai/inputNilai.html',
  styleUrls: ['inputNilai/inputNilai.scss'],
  animations: fuseAnimations,
  // encapsulation: ViewEncapsulation.None
})
export class inputNilai {
  dataNilai: faceNilai[] = [];

  nilaiForm: FormGroup;

  listMatkul: any = [];
  listBulan: any = [];
  tmpdata: any = [];
  mhs: any = [];

  listujian: jenisUjian[] = [];
  currentUnixTime = Date.now();

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<inputNilai>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) { }

  // uas = new FormControl('', [Validators.required]);
  // uts = new FormControl('', [Validators.required]);
  // tugas = new FormControl('', [Validators.required]);

  ngOnInit(): void {
    console.log(this.data);
    this.mhs = this.data.mhs;
    this.getMatkul();
    this.nilaiForm = this.formBuilder.group({
      periode_mulai: ['', Validators.required],
      periode_akhir: ['', Validators.required],
      tahun: ['', Validators.required],
      ujian: ['', Validators.required],
      // semester: ['0', [Validators.required, Validators.pattern('^[0-9.,]+$')]],
      nilai: this.formBuilder.array([
        this.getNilai()
      ])
    });

    this.loadUjian();
    this.listBulan = moment.months();
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  loadUjian() {
    this.db.list(this.mainDb + '/masterujian/').snapshotChanges().subscribe(items => {
      items.forEach(item => {
        this.listujian.push(getlistUjian(item.payload));
        console.log(this.listujian);
      })
    });
  }

  getMatkul() {
    this.data.matkul.forEach(item => {
      this.listMatkul.push({
        'id': item.id,
        'matkul': item.nama
      });
    });
    console.log(this.listMatkul);
  }

  getNilai() {
    const numberPatern = '^[0-9.,]+$';
    return this.formBuilder.group({
      matpel: ['', Validators.required],
      nharian: ['0', [Validators.required, Validators.pattern(numberPatern)]],
      nmid: ['0', [Validators.required, Validators.pattern(numberPatern)]],
      nakhir: ['0', [Validators.required, Validators.pattern(numberPatern)]],
      npraktek: ['0', [Validators.required, Validators.pattern(numberPatern)]],
      nraport: ['0', [Validators.required, Validators.pattern(numberPatern)]],
      // semester: ['0', [Validators.required, Validators.pattern(numberPatern)]],
    });
  }

  addRow() {
    const control = <FormArray>this.nilaiForm.controls['nilai'];
    control.push(this.getNilai());
  }
  removeUnit(e: number) {
    const control = <FormArray>this.nilaiForm.controls['nilai'];
    control.removeAt(e);
  }

  async simpan() {
    console.log(this.mhs.id);
    console.log(this.nilaiForm.value);

    this.loadSpinner();
    await this.nilaiForm.value.nilai.forEach(items => {
      this.tmpdata.push({
        'MataPelajaran': items.matpel,
        'NilaiHarian': items.nharian,
        'NilaiMid': items.nmid,
        'NilaiAkhir': items.nakhir,
        'NilaiAPraktek': items.npraktek,
        'NilaiRaport': items.nraport,
      })
    });
    await this.db.database.ref(this.mainDb + '/siswa/' + this.mhs.id + '/nilai/' + this.nilaiForm.value.ujian).set({
      'keterangan': {
        'periode': moment().month(this.nilaiForm.value.periode_mulai.toString()).format('MM') + '-' + moment().month(this.nilaiForm.value.periode_akhir.toString()).format('MM'),
        'tahun': this.nilaiForm.value.tahun,
      },
      // 'nilai':this.tmpdata,
    }).then(() => {
      this.nilaiForm.value.nilai.forEach(items => {
        this.db.database.ref(this.mainDb + '/siswa/' + this.mhs.id + '/nilai/' + this.nilaiForm.value.ujian).push({
          'MataPelajaran': items.matpel,
          'NilaiHarian': items.nharian,
          'NilaiMid': items.nmid,
          'NilaiAkhir': items.nakhir,
          'NilaiAPraktek': items.npraktek,
          'NilaiRaport': items.nraport,
        });
      });
      this.closeSpinner();
      this.dialogRef.close();
    });
  }
}
@Component({
  selector: 'SPP',
  templateUrl: 'SPP/spp.component.html',
  styleUrls: ['SPP/spp.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class inputSPP {
  ModelSpp: any = [];
  listBulan: any = [];
  currentUnixTime = Date.now();
  tmpbulan: any = [];
  tglHari: any = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<inputSPP>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    console.log(this.data);
    this.listBulan = moment.months('MMMM');
    console.log(this.listBulan);
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  async simpan() {
    this.loadSpinner();
    // console.log(this.ModelSpp);
    this.tmpbulan = moment().month(this.ModelSpp.bulan.toString()).format("MM") + this.ModelSpp.tahun;
    // console.log(this.tmpbulan);
    this.db.database.ref(this.mainDb + '/siswa/' + this.data.UserData.id + '/spp/' + this.tmpbulan).set({
      'bulan': this.ModelSpp.bulan,
      'tahun': this.ModelSpp.tahun,
      'tanggal': this.ModelSpp.tanggal.valueOf(),
      'jumlah': this.ModelSpp.jumlah
    }).then(() => {
      this.closeSpinner()
      this.dialogRef.close();
    });
  }
}

export const COMPONENT_LIST = [
  MahasiswaComponent, DialogMahasiswa, inputAbsensi, detailMhs, inputNilai, inputSPP
];


function getListMhs(id: string, jadwal: any[], nama: string, nilai: iNilai[], tingkat: string, jurusan: string, detailJurusan: string,
  profile: any, alamat: string, bio: string, gender: string, tempatlahir: string, absensi: any[], spp: any[], token: any[], tgllahir: any, tahun: string, kepalaAsrama: string, priode: string): MenuData {
  return {
    id: id,
    jadwal: jadwal,
    nama: nama,
    nilai: nilai,
    tingkat: tingkat,
    jurusan: jurusan,
    detailJurusan: detailJurusan,
    profile: profile,
    alamat: alamat,
    bio: bio,
    gender: gender,
    tempatlahir: tempatlahir,
    absensi: absensi,
    spp: spp,
    token: token,
    tgllahir: tgllahir,
    priode: priode,
    tahun: tahun,
    kepala: kepalaAsrama,
  };
}

function GetDataNilai(idSiswa: string, idUjian: string, MataPelajaran: string, NilaiAPraktek: string,
  NilaiAkhir: string, NilaiHarian: string, NilaiMid: string, NilaiRaport: string): iDataNilai {
  return {
    idSiswa: idSiswa,
    idUjian: idUjian,
    MataPelajaran: MataPelajaran,
    NilaiAPraktek: NilaiAPraktek,
    NilaiAkhir: NilaiAkhir,
    NilaiHarian: NilaiHarian,
    NilaiMid: NilaiMid,
    NilaiRaport: NilaiRaport
  };
}

export interface iDataNilai {
  idSiswa: string;
  idUjian: string;
  MataPelajaran: string;
  NilaiAPraktek: string;
  NilaiAkhir: string;
  NilaiHarian: string;
  NilaiMid: string;
  NilaiRaport: string;
}


function getNilaiMhs(mhsKey: string, uas: any[], uts: any[]): NilaiMhsData {
  return {
    mhsKey: mhsKey,
    uas: uas,
    uts: uts,
  };
}

function getMataKuliah(id: string, nama: string, kodeMatkul: string): MataKuliah {
  return {
    id: id,
    nama: nama,
    kodeMatkul: kodeMatkul
  };
}

export interface NilaiMhsData {
  mhsKey: string;
  uas: any[];
  uts: any[];
  // smsterKey: string;
  // matkul: any[];
  // semester: string;
}

export interface faceNilai {
  uas: number;
  uts: number;
  tugas: number;
}

export interface MataKuliah {
  id: string;
  nama: string;
  kodeMatkul: string;
}

export interface MenuData {
  id: string;
  jadwal: any[];
  nama: string;
  nilai: iNilai[];
  tingkat: string;
  jurusan: string;
  detailJurusan: string
  profile: string;
  alamat: string;
  bio: string;
  gender: string;
  tempatlahir: string;
  absensi: any[];
  spp: any[];
  token: any[];
  tgllahir: any;
  priode: string;
  tahun: string;
  kepala: string;
}

export interface iNilai {
  jenis: string;
  semester: string;
  nilai: any[];
}

export interface iJurusan {
  id: string,
  nama: string
}

export interface iDetailJur {
  idJur: string;
  id: string,
  nama: string
}

export interface iTingkat {
  id: string,
  tingkat: string
}

export interface jenisUjian {
  id: string,
  nama: string,
}

export interface iJadwal {
  idKelas: string;
  idJurusan: string;
  idDetailJurusan: string;
  idHari: string;
  idJadwal: string;
  guru: string;
  jam: any;
  MataPelajaran: string;
}

function getJadwal(idKelas: string, idJurusan: string,
  idDetailJurusan: string, idHari: string, idJadwal: string, guru: string, jam: any, MataPelajaran: string): iJadwal {
  return {
    idKelas: idKelas,
    idJurusan: idJurusan,
    idDetailJurusan: idDetailJurusan,
    idHari: idHari,
    idJadwal: idJadwal,
    guru: guru,
    jam: jam,
    MataPelajaran: MataPelajaran
  }
}

function getlistTingkat(data): iTingkat {
  return {
    id: data.key,
    tingkat: data.val()['tingkat'],
  }
}

function getlistUjian(data): jenisUjian {
  return {
    id: data.key,
    nama: data.val()['nama'],
  }
}

function getJurusan(id: string, nama: string): iJurusan {
  return {
    id: id,
    nama: nama
  };
}

function getDetailJur(idJur: string, id: string, nama: string): iDetailJur {
  return {
    idJur: idJur,
    id: id,
    nama: nama
  };
}