import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import {
    MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
    MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
    MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule,
    MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
    MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule,
    MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { ProgramComponent, COMPONENT_LIST} from './program.component';
import { ImageCropperModule} from 'ngx-image-cropper';
import { AppOverlayModule } from '../../overlay/overlay.module';
import { ProgressSpinnerModule,ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { HttpClientModule} from '@angular/common/http';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ckeditor4-angular';

import * as jquery from 'jquery';
window['jQuery'] = jquery;
window['$'] = jquery;

const routes = [
  {
      path: 'program',
      component: ProgramComponent
  }
];

@NgModule({
  declarations: [
      ProgramComponent,
      [...COMPONENT_LIST],
  ],
  providers: [
      
    ],
  entryComponents: COMPONENT_LIST,
  imports     : [
      HttpClientModule,
      CKEditorModule,
      BrowserModule,
      RouterModule.forChild(routes),
      MatAutocompleteModule,
      MatBadgeModule,
      MatBottomSheetModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatDatepickerModule,
      MatDialogModule,
      MatDividerModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSlideToggleModule,
      MatSliderModule,
      MatSnackBarModule,
      MatSortModule,
      MatStepperModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      MatTreeModule,
      TranslateModule,
      AppOverlayModule,
      ProgressSpinnerModule,
      BrowserModule,
      FuseSharedModule,
      ImageCropperModule
  ],
  exports     : [
      
     
  ]
})
export class ProgramModule { }
