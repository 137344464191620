import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ImageCropperModule, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable, of } from 'rxjs';
import { finalize, subscribeOn, delay } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import * as XLSX from 'xlsx';
declare var jQuery: any;
import * as $ from 'jquery';
import * as moment from 'moment';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-matapelajaran',
  templateUrl: './matapelajaran.component.html',
  styleUrls: ['./matapelajaran.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class MatapelajaranComponent implements OnInit {
  listMatpel: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;
  displayedColumns = ['kode', 'matpel'];

  isLoading = true;
  mainDb = environment.mainDb;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private _fuseSplashScreenService: FuseSplashScreenService, public _MatDialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() {
    this.db.list(this.mainDb + '/masterPelajaran/').snapshotChanges().subscribe(items => {
      this.listMatpel = [];
      items.forEach(item => {
        this.listMatpel.push(getMenuData(item.key, item.payload.val()['kodePelajaran'], item.payload.val()['nama']));
        console.log(this.listMatpel);
      });
      setTimeout(() => {
        of(this.listMatpel).pipe(delay(800))
          .subscribe(data => {
            this.isLoading = false;
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }, error => this.isLoading = false);
      }, 500);
    });
  }

  AddData() {
    const dialogRef = this._MatDialog.open(TambahMatpel, {
      panelClass: 'dialog',
      width: '500px',
      hasBackdrop: true,
      data: {
        action: 'new'
      }
    });
  }
}

@Component({
  selector: 'tambahmatpel',
  templateUrl: 'tambahmatpel/tambahmatpel.component.html',
  styleUrls: ['./tambahmatpel/tambahmatpel.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class TambahMatpel {
  matpelForm: FormGroup;
  modelMatpel: any = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  mainDb = environment.mainDb;

  currentUnixTime = Date.now();
  constructor(
    public dialogRef: MatDialogRef<TambahMatpel>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.matpelForm = new FormGroup({
      kode: new FormControl(''),
      nama: new FormControl(''),
    });

    if (this.data.action === 'new') {

    } else {

    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  simpan() {
    this.loadSpinner();
    console.log(this.modelMatpel);
    this.db.database.ref(this.mainDb + '/masterPelajaran/' + this.currentUnixTime).set({
      'kodePelajaran': this.modelMatpel.kode,
      'nama': this.modelMatpel.nama
    }).then(() => {
      this.closeSpinner();
      this.dialogRef.close();
    });
  }
}

function getMenuData(id: string, kodePelajaran: string, nama: string): MenuData {
  return {
    id: id,
    kodePelajaran: kodePelajaran,
    nama: nama,
  }
}

export interface MenuData {
  id: string;
  kodePelajaran: string;
  nama: string;
}
export const COMPONENT_LIST = [
  MatapelajaranComponent,
  TambahMatpel
]
